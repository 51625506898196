import { createHashHistory } from 'history';
import { PARSE_SERVER_URL, PARSE_SERVER_APP_ID }  from "../config/index";
import ParseClient  from '../service/ParseService';

const history = createHashHistory();


const parseConfig = {    
    URL: PARSE_SERVER_URL,
    JAVASCRIPT_KEY: null,
    APP_ID: PARSE_SERVER_APP_ID
}

const dataProvider = ParseClient(parseConfig);

export const provider = {
    history, 
    dataProvider
} 