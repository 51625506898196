import store from '../reducer'
/**
 * HEAD KEYCLOCK AUTH STARTED HERE
 *
*/

const initKeycloak = async (_kc) => {
  console.log('V props props props')
  let kc = null
  await _kc.init({ onLoad: 'login-required' })
    .then((authenticated) => {
        if (authenticated) {
            // store.dispatch({type: "AUTH_SUCCESS", payload: {currentUser: _kc.idTokenParsed, auth: true}})
            // console.log('user auth')
            kc = _kc
        } else {
            //store.dispatch({type: "AUTH_FAILLED"})
        }
    })
  return kc
};

const checkKeycloakStatus = async (_kc) => { 
  
  let kc = null
  await _kc.init({ onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin+ '/silent-check-sso.html'}).then((authenticated) => {
    if (authenticated) {
      // store.dispatch({type: "AUTH_SUCCESS", payload: {currentUser: _kc.idTokenParsed, auth: true}})
      // console.log('user auth')
      kc = _kc
  } else {
      //store.dispatch({type: "AUTH_FAILLED"})
  }
});
   
  return kc
};


const AuthService = {
  initKeycloak,
  checkKeycloakStatus
};

export default AuthService;
