import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Snack from '../../components/Snack'
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {getTransList, countTransaction, saveTransactionFile, ChangeTransactionStatus} from '../../utility'
import { Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import {colors} from '../../themes'
import {exts} from "./extension"

import { Responsive } from "responsive-react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux';
import CommentPopup from '../../components/CommentPopup'
import FormControl from '@material-ui/core/FormControl';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

function createData(transID, name, type, createdAt, userMain, userSecond, amount,  amount_paid, amout_receive, status, source, task_name, task_type, payment_proof, validator) {
  return {
    transID,
    name,
    type,
    createdAt,
    userMain,
    userSecond,
    amount,
    amount_paid,
    amout_receive,
    status,
    source,
    task_name,
    task_type,
    payment_proof,
    validator
  };
}

let rows = []
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Transaction ID',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Reason',
  },
  {
    id: 'createAt',
    numeric: false,
    disablePadding: false,
    label: 'Date & Time',
  },
  {
    id: 'in',
    numeric: false,
    disablePadding: false,
    label: 'User main',
  },
  {
    id: 'userSecond',
    numeric: false,
    disablePadding: false,
    label: 'User second',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'amount_paid',
    numeric: true,
    disablePadding: false,
    label: 'Amount Paid',
  },
  {
    id: 'amout_receive',
    numeric: true,
    disablePadding: false,
    label: 'Amount received',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'validator',
    numeric: true,
    disablePadding: false,
    label: 'Validator',
  },
  {
    id: 'source',
    numeric: true,
    disablePadding: false,
    label: 'Source or connection',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* 
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
        align={'left'}
        padding={'normal'}
        >
            <TableSortLabel
                //active={orderBy === headCell.id}
                //direction={orderBy === headCell.id ? order : 'asc'}
                >
                {"Actions"}
            </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transaction
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export function Transactions() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [numberItem, setNumberItem] = React.useState(0);
  const [typeSnack, setTypeSnack] = React.useState("success");

  const [messageSkack, setMessageSnack] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openComment, changeStateComment] = React.useState(false);

  const temporaireOnbject = useSelector(p => p.app.transactionEdited)

  
  const dispatch = useDispatch()
  
  const setTemporaire = (id) =>{
    dispatch({type: "SAVE_TRANSACTION_EDITED", id: id})
  }
  const setCommentID = (id) =>{
    dispatch({type: "SAVE_COMMENT_ID", id: id})
  }
  const setTranasactionState = (state) =>{
    dispatch({type: "SAVE_TRANSACTION_STATE", state: state})
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const [tabData, setTabData] = React.useState([])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tabData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage)
    setPage(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onChangeStatus = (row, comment, sendMail, label) => {
    let cloud = "validateDepositTransaction"
    if(label ==="Cancel"){
      cloud = "cancelTransaction"
    }
    (async function(){
      if(!window.confirm(label+' this transaction ?')) return
      const res = await ChangeTransactionStatus(row.name, comment, cloud, sendMail)
      calculTabData(page, rowsPerPage)
      if(!res.error){
        setMessageSnack("Tansaction has been "+(label==="Cancel" ? 'cancelled !' : 'validated !'))
        setTypeSnack("success")
       setTemporaire(row.name)
      }else{
        setMessageSnack(res.message)
        setTypeSnack("error")
       setTemporaire(null)
      }
      setOpenSnack(true)
    })()
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberItem) : 0;

    async function calculTabData(page, max){

        let locRow = []  
        let transcations = await getTransList(page, max);
        if(transcations){
            console.log('transcations transcations transcationsv', transcations)
            for(let i = 0; i < transcations.length; i++){
                const tem =  transcations[i]
                const date =  String(new Date(tem.createdAt).toString().split('GMT')[0])
                const task_type = tem.transactionType?.task_name
                const payment_proof = tem?.payment_proof?.url
    
                const userMain = tem.userMain? (tem.userMain.last_name? tem.userMain.last_name : "")+''+(tem.userMain.first_name? tem.userMain.first_name : "") : ""
                const userSecond = tem.userSecond? (tem.userSecond.last_name? tem.userSecond.last_name:"") + ""+(tem.userSecond.first_name?tem.userSecond.first_name: "") : ""
                
                locRow.push(
                    createData(
                        tem.transaction_id, 
                        tem.objectId, 
                        tem.transactionType?.name, 
                        date, userMain, 
                        userSecond,  
                        parseFloat(tem.amount).toFixed(2), 
                        parseFloat(tem.amount_paid).toFixed(2), 
                        parseFloat(tem.amount_received).toFixed(2), 
                        tem.transactionStatus?.name, 
                        tem.location?.city, 
                        tem.transactionStatus?.task_name, 
                        task_type,
                        payment_proof,
                        tem.validator
                    )
                )
            }
            console.log('locRow ***************** locRow', locRow)
            setTabData(locRow)
        }
    }

    React.useEffect(()=>{
        (async ()=>{
            const count = await countTransaction()
            setNumberItem(count)
            console.log('count count count count', count)
            await calculTabData(0, 5)
        })()
    }, [])
    const isMobile = useMediaQuery({ maxWidth: 767 })


    const handleChange = async (e, idTransaction) => {
      const file = e.target.files[0];
  
      var reader = new FileReader();
       reader.readAsDataURL(file);
       let result;
       reader.onload =  async  () => {
        if(exts.includes((file.name).split('.')[1])){
          const res = await saveTransactionFile(idTransaction,  {base64: reader.result})
          if(!res){
            window.alert('Error uploading contract !')
          }
          calculTabData(page, rowsPerPage)
        }else{
          window.alert('File format is not supported !')
        }
       };
       reader.onerror = function (error) {
         console.log('Error: ', error);
       };
    }

  return (
    <Box sx={{ width: '100%' }}>
      <Title title="TRANSACTION" />
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Responsive displayIn={["Laptop", "Tablet"]}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberItem}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {//stableSort(tabData, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  tabData.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding={'normal'}
                        >
                          {row.transID}
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.createdAt}</TableCell>
                        <TableCell align="left">{row.userMain}</TableCell>
                        <TableCell align="left">{row.userSecond}</TableCell>
                        <TableCell align="left">{row.amount}</TableCell>
                        <TableCell align="left">{row.amount_paid}</TableCell>
                        <TableCell align="left">{row.amout_receive}</TableCell>
                        <TableCell style={{width: "200px"}}>
                          <span style={{
                              color: row.task_name === "success" ? colors.primary :  row.task_name === "fail" ? "#C82333" : row.task_name === "pending" ? "#138496" : "#E0A800", 
                              paddingLeft: '1px', 
                              paddingRight: '1px',
                              borderRadius: '10px',
                              // color: 'white',
                              fontSize: '13px',
                              marginTop: '-5px',
                              fontWeight: 'bold',
                              position: "relative",
                              paddingTop: '5px',
                              paddingBottom: '5px'
                              }} >
                                {row.status}
                              </span>
                        </TableCell> 
                        <TableCell align="left">
                            {row.validator.map((f, i)=>
                              <div>
                                  <p key={i}>{f}</p>
                              </div>
                            )}
                          </TableCell>
                        <TableCell align="left">{row.source}</TableCell>
                        <TableCell align="left">
                          {row.task_name === "pending" &&
                            <CommentPopup
                              label={"Cancel"}
                              row={row}
                              trans
                              onChangeStatus={onChangeStatus}
                              textarea={"Cancel comment"}
                            />
                          }

                              {/* {row.task_name === "pending" && !temporaireOnbject.includes(row.name) &&
                                  <Button 
                                      //variant="contained" 
                                      style={{color: colors.primary}}
                                      onClick={()=>
                                          (async function(){
                                              if(!window.confirm('Cancel this transaction ?')) return
                                              const res = await ChangeTransactionStatus(row.name)
                                              calculTabData(page, rowsPerPage)
                                              if(!res.error){
                                                setMessageSnack("Tansaction has been cancelled !")
                                                setTypeSnack("success")
                                                setCommentID(row.name)
                                                setTranasactionState("Cancelled")
                                               setTemporaire(row.name)
                                               changeStateComment(true)
                                              }else{
                                                setMessageSnack(res.message)
                                                setTypeSnack("error")
                                               setTemporaire(null)
                                              }
                                              setOpenSnack(true)
                                            })()
                                      }
                                  >
                                      Cancel
                                  </Button>
                              } */}

                              {row.task_type === "deposit" 
                                && row.task_name === "pending" 
                                && row.validator&&row.validator.length === 0 &&
                                <CommentPopup
                                  label={"Validate"}
                                  row={row}
                                  trans
                                  onChangeStatus={onChangeStatus}
                                  textarea={"Validation comment"}
                                />
                              }

                              {/* {row.task_type === "deposit" && row.task_name === "pending" && !temporaireOnbject.includes(row.name) &&
                                  <Button 
                                      //variant="contained" 
                                      style={{color: colors.primary}}
                                      onClick={()=>
                                          (async function(){
                                              if(!window.confirm('Validate this transaction ?')) return
                                              const res = await validateDepositTransaction(row.name)
                                              calculTabData(page, rowsPerPage)
                                              if(!res.error){
                                                setTypeSnack("success")
                                                setMessageSnack("Tansaction has been validated !")
                                               setTemporaire(row.name)
                                               setCommentID(row.name)
                                               setTranasactionState("Validated")
                                               changeStateComment(true)
                                              }else{
                                                setTypeSnack("error")
                                               setTemporaire(null)
                                                setMessageSnack(res.message)
                                              }
                                              
                                              setOpenSnack(true)
                                            })()
                                      }
                                  >
                                      validate
                                  </Button> */}
                                  {row.task_type === "deposit"&&
                                    <Typography component="div" variant="h5" >
                                      <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
                                      {row.task_name === "pending"&&
                                        <>
                                          <label for={index} >
                                              <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: "pointer"}} />
                                          </label>
                                          <input 
                                            id={index} 
                                            //disabled={!!row.contract_certificate}
                                            type="file" 
                                            onChange={(e) =>handleChange(e, row.name)} 
                                            style={{display: 'none'}}
                                          /> 
                                        </>
                                      }
                                        {!!row.payment_proof&&
                                          <a href={row.payment_proof} style={{fontSize: "12px"}} target="_blank">Payment proof</a>
                                        }
                                      </FormControl>  
                                    </Typography>
                                  }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

        </Responsive>

        {
          //Mobile view started here ********
        }



        <Responsive displayIn={["Mobile"]}>
          {tabData.map((row, index) => {
              return (
                <CardResponsiveContract
                  row={row}
                  key={index}
                  cle={index}
                  calculTabData={calculTabData}
                  page={page} 
                  rowsPerPage={rowsPerPage}
                  setTypeSnack={setTypeSnack}
                  setMessageSnack={setMessageSnack}
                  setTemporaire={setTemporaire}
                  setOpenSnack={setOpenSnack}
                  temporaireOnbject={temporaireOnbject}
                  setTranasactionState={setTranasactionState}
                  changeStateComment={changeStateComment}
                  setCommentID={setCommentID}
                  onChangeStatus={onChangeStatus}
                  handleChange={handleChange}
                />
              );
            })}
          
        </Responsive>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberItem}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={isMobile? "Rows:" : "Rows per page:"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snack open={openSnack} message={messageSkack} muteSnack={setOpenSnack} type={typeSnack}/>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

function CardResponsiveContract({row, temporaireOnbject, onChangeStatus, handleChange}){
  return(
    <>
      <br/>
      <Card sx={{ minWidth: 275 }} style={{borderColor: 'red'}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {row.type} 
            <b style={{marginLeft: "20px"}}> 
              <i>{row.inOrOut}</i>
            </b>
            <span style={{
              backgroundColor: row.task_name === "success" ? colors.primary :  row.task_name === "fail" ? "#C82333" : row.task_name === "pending" ? "#138496" : "#E0A800", 
              paddingLeft: '20px', 
              paddingRight: '20px',
              borderRadius: '10px',
              color: 'white',
              fontSize: '13px',
              marginTop: '-5px',
              paddingTop: '5px',
              paddingBottom: '5px',
              float: 'right'
              }} 
            >
              {row.status}
            </span>
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount:{" "}
            </Typography>
            {row.amount}
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount paid:{" "}
            </Typography>
            {row.amount_paid}
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Amount received:{" "}
            </Typography>
            {row.amout_receive}
          </Typography>

          <Typography variant="body2">
          <i>User main:</i>{" "}{row.userMain}
          </Typography>
          <Typography variant="body2">
          <i>User second:</i>{" "}{row.userSecond}
          </Typography>
          {row.validator && row.validator.length > 0&&
            <Typography variant="body2">
              <i>Validator:</i>
              <span>
                {row.validator.map((f, i)=>
                      <p key={i}>{f}</p>
                )}
              </span>
            </Typography>
          }

          <Typography variant="body2">
          <i>Source or connection:</i>{" "}{row.source}
          </Typography>
          
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Date:{"   "}
            <span style={{textAlign: 'center'}} >
              {row.createdAt}
            </span>
            </Typography>
        </CardContent>
        <CardActions>
          {row.task_name === "pending" &&
            <CommentPopup
              label={"Cancel"}
              row={row}
              trans
              onChangeStatus={onChangeStatus}
              textarea={"Cancel comment"}
            />
          }
          {row.task_type === "deposit" && row.task_name === "pending" && !temporaireOnbject.includes(row.name) &&
            <CommentPopup
              label={"Validate"}
              row={row}
              trans
              onChangeStatus={onChangeStatus}
              textarea={"Validation comment"}
            />
          }

          {/* {row.task_name === "pending" && !temporaireOnbject.includes(row.name) &&
              <Button 
                  //variant="contained" 
                  style={{color: colors.primary}}
                  onClick={()=>
                      (async function(){
                          if(!window.confirm('Cancel this transaction ?')) return
                          const res = await ChangeTransactionStatus(row.name)
                          calculTabData(page, rowsPerPage)
                          if(!res.error){
                            setMessageSnack("Tansaction has been cancelled !")
                            setTypeSnack("success")
                            setTemporaire(row.name)
                            setCommentID(row.name)
                            setTranasactionState("Cancelled")
                            changeStateComment(true)
                          }else{
                            setMessageSnack(res.message)
                            setTypeSnack("error")
                            setTemporaire(null)
                          }
                          setOpenSnack(true)
                        })()
                  }
              >
                  Cancel
              </Button>
          } */}
          {/* {row.task_type === "deposit" && row.task_name === "pending" && !temporaireOnbject.includes(row.name) &&
              <Button 
                  //variant="contained" 
                  style={{color: colors.primary}}
                  onClick={()=>
                      (async function(){
                          if(!window.confirm('Validate this transaction ?')) return
                          const res = await validateDepositTransaction(row.name)
                          calculTabData(page, rowsPerPage)
                          if(!res.error){
                            setTypeSnack("success")
                            setMessageSnack("Tansaction has been validated !")
                            setTemporaire(row.name)
                            setCommentID(row.name)
                            setTranasactionState("Validated")
                            changeStateComment(true)
                          }else{
                            setTypeSnack("error")
                            setTemporaire(null)
                            setMessageSnack(res.message)
                          }
                          
                          setOpenSnack(true)
                        })()
                  }
              >
                  validate
              </Button>
          } */}
          {row.task_type === "deposit"&&
              <Typography component="div" variant="h5" >
                <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
                {row.task_name === "pending"&&
                    <>
                      <label for={row.name} >
                          <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: "pointer"}} />
                      </label>
                      <input 
                        id={row.name}
                        //disabled={!!row.contract_certificate}
                        type="file" 
                        onChange={(e) =>handleChange(e, row.name)} 
                        style={{display: 'none'}}
                      /> 
                    </>
                }
                  {!!row.payment_proof&&
                    <a href={row.payment_proof} style={{fontSize: "12px"}} target="_blank">Payment proof</a>
                  }
                </FormControl>  
              </Typography>
            }
        </CardActions>
      </Card>
    </>
  )
}
