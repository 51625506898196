import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@mui/material/Tooltip";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "./PackRia.css";
import ReactTooltip from "react-tooltip";
import { colors, styleMain } from "../themes";
import { packagesLocale } from "./Packages";
import { InvestModal } from "./InvestModal";
import { useSelector } from "react-redux";
import { getInvestPlans } from "../utility/project";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Price = (props) => {
  const [open, setOpen] = React.useState(false);
  const hideDialog = (roload) => {
    setOpen(false);
    if (roload) {
      props.handleClose(roload);
    }
  };
  return (
    <Grid item md={4} xs={12}>
      <div class="section-plan">
        <div className="card">
          <div className="card-top">
            <h2>{props.name}</h2>
          </div>
          <div className="plan-content">
            <div className="d-flex">
              <ul className="nav-list">
                <li>
                  Amount (RIA)
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title="This is a number of RIA you need to invest in the corresponding formula."
                    data-for={"main" + props.id0}
                    data-iscapture="true"
                    data-tip={
                      "This is a number of RIA you need to invest in the corresponding formula."
                    }
                  />
                </li>
                <li>
                  Annual return
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title="To annual return of the investment."
                    data-for={"main" + props.id1}
                    data-iscapture="true"
                    data-tip={"To annual return of the investment."}
                  />
                </li>
                <li>
                  Maturity (month)
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title="Duration of this investment contract"
                    data-for={"main" + props.id2}
                    data-iscapture="true"
                    data-tip={"Duration of this investment contract."}
                  />
                </li>

                <li>
                  Preferred stock
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title="Your profit is paid before any ordinary shareholder."
                    data-for={"main" + props.id3}
                    data-iscapture="true"
                    data-tip={
                      "Your profit is paid before any ordinary shareholder."
                    }
                  />{" "}
                </li>

                <li>
                  Cumulative stock
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    data-for={"main" + props.id4}
                    title="Any missed payout will be added to the next payment cycle."
                    data-iscapture="true"
                    data-tip={
                      "Any missed payout will be added to the next payment cycle."
                    }
                  />
                </li>
                <li>
                  Participating stock
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    data-for={"main" + props.id5}
                    data-iscapture="true"
                    title={
                      props.object.formule === "A"
                        ? "This is not available for A- formula"
                        : "You earn your ROI or that of ordinary shareholder, which one is higher!"
                    }
                    data-tip={
                      props.object.formule === "A"
                        ? "This is not available for A- formula"
                        : "You earn your ROI or that of ordinary shareholder, which one is higher!"
                    }
                  />{" "}
                </li>
                <li>
                  Convertible stock
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title={
                      props.object.formule === "A"
                        ? "This is not available for A- formula"
                        : props.object.formule === "B"
                        ? "This is not applicable for B formula"
                        : "You are entitled to convert your RIA into ordinary shares, provided conditions set by the BOD are met."
                    }
                    data-for={"main" + props.id6}
                    data-iscapture="true"
                    data-tip={
                      props.object.formule === "A"
                        ? "This is not available for A- formula"
                        : props.object.formule === "B"
                        ? "This is not applicable for B formula"
                        : "You are entitled to convert your RIA into ordinary shares, provided conditions set by the BOD are met."
                    }
                  />
                </li>

                <li>
                  Weekly Payout (Year)
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    title="Payout sent in your payout account each weekPayout sent in your payout account each week"
                    data-for={"main" + props.id10}
                    data-iscapture="true"
                    data-tip={"Payout sent in your payout account each week"}
                  />
                </li>
                <li>
                  Total monthly cash-out (12x)
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    data-for={"main" + props.id7}
                    title={
                      props.object.formule === "A"
                        ? "This is the monthly cash out if you opt for 6x monthly cash-out terms."
                        : props.object.formule === "B"
                        ? "This is the monthly cash out if you opt from 6th-18th month."
                        : "This is the monthly cash out if you opt for 12x monthly cash-out terms."
                    }
                    data-iscapture="true"
                    data-tip={
                      props.object.formule === "A"
                        ? "This is the monthly cash out if you opt for 6x monthly cash-out terms."
                        : props.object.formule === "B"
                        ? "This is the monthly cash out if you opt from 6th-18th month."
                        : "This is the monthly cash out if you opt for 12x monthly cash-out terms."
                    }
                  />
                </li>
                <li>
                  Total yearly cash-out (1x)
                  <img
                    src="https://www.mctibv.com/img/quest2.png"
                    alt=""
                    data-for={"main" + props.id8}
                    data-iscapture="true"
                    title={
                      props.object.formule !== "C"
                        ? "This is the monthly cash out if you opt for 12x monthly cash-out terms."
                        : "This is the monthly cash out if you opt for 1x yearly cash-out term."
                    }
                    data-tip={
                      props.object.formule !== "C"
                        ? "This is the monthly cash out if you opt for 12x monthly cash-out terms."
                        : "This is the monthly cash out if you opt for 1x yearly cash-out term."
                    }
                  />
                </li>
              </ul>
              <ul className="nav-list nav-list1">
                <li>{props.v1}x</li>
                <li>{props.v2}% </li>
                <li>{props.v3} </li>

                <li>{props.object?.preferred_stock ? "Yes" : "No"} </li>
                <li>{props.object?.cumulative_stock ? "Yes" : "No"} </li>
                <li>{props.object?.participating_stock ? "Yes" : "No"} </li>
                <li>{props.object?.convertible_stock ? "Yes" : "No"} </li>

                <li>{(props.v9 / 52).toFixed(2)} </li>
                <li>{props.v8} </li>
                <li>{props.v9} </li>
              </ul>
            </div>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              style={{
                backgroundColor: colors.primary,
                color: "white",
                ...styles.customButton,
              }}
            >
              Choose the plan
            </Button>
          </div>
        </div>
      </div>
      <InvestModal open={open} hideDialog={hideDialog} pack={props} />
    </Grid>
  );
};

export default function PackRIA(props) {
  const handleClickOpen = () => {
    props.onChangeState(true);
  };

  const handleClose = (reload) => {
    props.onChangeState(false, reload);
  };

  const packages = useSelector((p) => p.app.packages);

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ backgroundColor: colors.primary }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Investment plan
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <div class="container">
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "90px",
              }}
            >
              {props.packs ? (
                <Grid container spacing={2}>
                  {props.packs &&
                    props.packs.length > 0 &&
                    props.packs.map((p, i) => (
                      <Price
                        handleClose={handleClose}
                        task_name={p.task_name}
                        key={i}
                        name={p.pack}
                        object={p}
                        //t0={packagesLocale.filter(a => a.task_name === p.task_name)[0].t0}
                        // t1={packagesLocale.filter(a => a.task_name === p.task_name)[0].t1}
                        // t2={packagesLocale.filter(a => a.task_name === p.task_name)[0].t2}
                        // t3={packagesLocale.filter(a => a.task_name === p.task_name)[0].t3}
                        // t4={packagesLocale.filter(a => a.task_name === p.task_name)[0].t4}
                        // t5={packagesLocale.filter(a => a.task_name === p.task_name)[0].t5}
                        // t6={packagesLocale.filter(a => a.task_name === p.task_name)[0].t6}
                        // t7={packagesLocale.filter(a => a.task_name === p.task_name)[0].t7}
                        // t8={packagesLocale.filter(a => a.task_name === p.task_name)[0].t8}
                        // t10={packagesLocale.filter(a => a.task_name === p.task_name)[0].t10}

                        v1={p.investment_asset}
                        v2={p.annual_roi}
                        v3={p.duration}
                        // v4={packagesLocale.filter(a => a.task_name === p.task_name)[0].v4}
                        // v5={packagesLocale.filter(a => a.task_name === p.task_name)[0].v5}
                        // v6={packagesLocale.filter(a => a.task_name === p.task_name)[0].v6}
                        // v7={packagesLocale.filter(a => a.task_name === p.task_name)[0].v7}

                        v8={p.cumul_monthly_payout}
                        v9={p.one_time_payout}
                        id0="0"
                        id1="1"
                        id2="2"
                        id3="3"
                        id4="4"
                        id5="5"
                        id6="6"
                        id7="7"
                        id8="8"
                        id10="9"
                      />
                    ))}
                </Grid>
              ) : (
                <CircularProgress size={100} />
              )}
            </Box>
          </div>
        </div>
        {Array(82)
          .fill()
          .map((a, i) => (a = i))
          .sort(() => Math.random() - 0.5)
          .sort((a, b) => a - b)
          .map((i) => (
            <ReactTooltip
              id={"main" + i}
              place={"top"}
              type={"dark"}
              effect={"float"}
              multiline={true}
            />
          ))}
      </Dialog>
    </div>
  );
}

const styles = {
  card: {
    backgroundColor: colors.secondary,
    textAlign: "center",
    // width: "200px"
  },
  button: {
    textDecoration: "none",
  },
  customButton: {
    width: "180px",
  },
};
