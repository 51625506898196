import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import { getInvestmentType, saveProject } from "../../../utility";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { listProject } from "../../../utility/project";
import { useForm } from "react-hook-form";

export default function ProjectAdd() {
  const [amount, setAmount] = React.useState(0);
  const [number_of_payment, setNumberOfPayment] = React.useState(1);
  const [name, setName] = React.useState("");
  const [start_date, setStartDate] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [month_investment_maturity, setMonthInvest] = React.useState(0);
  const [return_rate, setRate] = React.useState(0);
  const [investmentType, setType] = React.useState("");
  const [picture, setLogo] = React.useState(null);
  const [investmentTypes, setInvestmentType] = React.useState([]);
  const [complete_description, setDocumentDescription] = React.useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [projectForm, onSHowProjectForm] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [resume, setResume] = React.useState("");
  const [impact, setImpact] = React.useState("");
  const [managment, setManag] = React.useState("");
  const [investplan, setInvestPlan] = React.useState("");
  const [risk, setRisk] = React.useState("");

  /* const onSubmit = () => {
    console.log(
      name,
      description, 
      picture,
      month_investment_maturity,
      investmentType,
      start_date,
      return_rate,
      investmentType, 
      complete_description
    );

      if(picture){
  
        var reader = new FileReader();
        reader.readAsDataURL(picture);

        reader.onload =  async  () => {
          var reader2 = new FileReader();
          reader2.readAsDataURL(complete_description);
          
          reader2.onload =  async  () => {
            const res = await saveProject({
                name,
                description, 
                picture: {base64: reader.result},
                month_investment_maturity,
                investmentType,
                start_date,
                amount,
                return_rate,
                number_of_payment: parseInt(number_of_payment),
                complete_description : {base64: reader2.result}

            })

            console.log('saving project result', res)

            onSHowProjectForm(false)
            listProject()
          }

          reader2.onerror = function (error) {
            console.log('Error reader 2: ', error);
          };

        };

        reader.onerror = function (error) {
          console.log('Error: ', error);
        };

      }else{
        window.alert('Project picture must be provided')
      }

  }; */
  const onSubmit = (data) => {
    console.log(data);
    var reader = new FileReader();
    reader.readAsDataURL(data.picture[0]);

    reader.onload = async () => {
      // var reader2 = new FileReader();
      // reader2.readAsDataURL(data.complete_description[0]);

      // reader2.onload = async () => {
        const res = await saveProject({
          name: data.name,
          description: data.description,
          picture: { base64: reader.result },
          month_investment_maturity: data.month_investment_maturity,
          investmentType: data.investmentType,
          start_date: data.start_date,
          monthly_repayment: data.monthly_repayment,
          amount: data.amount,
          return_rate: data.return_rate,
          number_of_payment: parseInt(number_of_payment),
          //complete_description: { base64: reader2.result },
          impact: impact,
          project_risks: risk,
          resume: resume,
          managment_description: managment,
          financial_plan: investplan,
          pdg_name: data.pdg,
          activity_sector: data.activity_sector,
          turnover: data.turnover,
          localisation: data.localisation,
          legal_form: data.legal_form,
          number_employees: data.number_employees,
        });

         console.log("saving project result", res);

        onSHowProjectForm(false);
        listProject();
      // };

      // reader2.onerror = function (error) {
      //   console.log("Error reader 2: ", error);
      // };
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  async function getType() {
    const results = await getInvestmentType();

    console.log(results);
    setInvestmentType(results);
  }

  React.useEffect(() => {
    getType();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!projectForm && (
          <Button
            variant="contained"
            onClick={() => onSHowProjectForm(!projectForm)}
          >
            New Project
          </Button>
        )}
      </Box>

      {projectForm && (
        <Paper elevation={5}>
          <Stack
            gap={5}
            spacing={2}
            alignItems="center"
            style={{ paddingBottom: "30px" }}
          >
            <Box>
              <Typography component="div" variant="h4" sx={{ marginTop: 4 }}>
                New Project
              </Typography>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "50ch" },
              }}
              xs={12}
              md={8}
              sm={8}
              lg={8}
              noValidate
              // onSubmit={onSubmit}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div>
                <TextField
                  type="file"
                  required
                  label="Project picture"
                  {...register("picture", { required: true })}
                  error={errors.picture}
                  helperText={errors.picture ? "Field required" : ""}
                  InputLabelProps={{
                    shrink: true,
                  }} 
                // onChange={(e) => setLogo(e.target.files[0])}
                />

                {/* <TextField
                  id="outlined-number"
                  label="Number of payment"
                  required
                  {...register("number_of_payment", { required: true })}
                  type="number"
                  error={errors.number_of_payment}
                  helperText={errors.number_of_payment ? "Field required" : ""}
                  
                /> */}
                <TextField
                  id="outlined-number"
                  label="Monthly payment"
                  required
                  {...register("monthly_repayment", { required: true })}
                  type="number"
                  error={errors.monthly_repayment}
                  helperText={errors.monthly_repayment ? "Field required" : ""}
                />
              </div>
              <div>
                <TextField
                  id="outlined-disabled"
                  required
                  error={errors.name}
                  helperText={errors.name ? "Field required" : ""}
                  {...register("name", { required: true })}
                  // onChange={(e) => setName(e.target.value)}
                  label="Name"
                  />

                <TextField
                    id="date"
                    label="Start date"
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }} 
                    helperText={errors.name ? "Field required" : ""}
                    {...register("start_date", { required: true })}
                  />

                
              </div>
              <div>
                <TextField
                  id="outlined-disabled"
                  required
                  error={errors.pdg}
                  {...register("pdg", { required: true })}
                  helperText={errors.name ? "Field required" : ""}
                  // onChange={(e) => setName(e.target.value)}
                  label="PDG"
                />

                <TextField
                    label="Localisation" 
                    {...register("localisation", { required: true })}
                    helperText={errors.localisation ? "Field required" : ""}
                  />

                
              </div>
              <div>
                <TextField
                  id="outlined-disabled"
                  required
                  error={errors.activity_sector}
                  {...register("activity_sector", { required: true })}
                  helperText={errors.activity_sector ? "Field required" : ""}
                  // onChange={(e) => setName(e.target.value)}
                  label="Sector of activity"
                />

                <TextField
                    label="Turnover" 
                    type="number"
                    {...register("turnover", { required: true })}
                    helperText={errors.turnover ? "turnover" : ""}
                  />

                
              </div>
              <div>
                <TextField
                  id="outlined-disabled"
                  required
                  error={errors.legal_form}
                  {...register("legal_form ", { required: true })}
                  helperText={errors.legal_form  ? "Field required" : ""}
                  // onChange={(e) => setName(e.target.value)}
                  label="Legal form "
                />

                <TextField
                    label="Number of employees" 
                    type="number"
                    {...register("number_employees", { required: true })}
                    helperText={errors.number_employees ? "Field required" : ""}
                  />

                
              </div>

              <div>
                <TextField
                  id="outlined-number"
                  label="Amount"
                  required
                  type="number"
                  error={errors.amount}
                  {...register("amount", { required: true })}
                  helperText={errors.amount ? "Field required" : ""}
                //  onChange={(e) => setAmount(e.target.value)}
                />
                <TextField
                  type="number"
                  required
                  error={errors.return_rate}
                  {...register("return_rate", { required: true })}
                  helperText={errors.return_rate ? "Field required" : ""}
                  //  onChange={(e) => setRate(e.target.value)}
                  label="Rate"
                />
              </div>

              <div>
                <TextField
                  id="outlined-number"
                  required
                  label="Month investment maturity "
                  {...register("month_investment_maturity", { required: true })}
                  error={errors.month_investment_maturity}
                  helperText={
                    errors.month_investment_maturity ? "Field required" : ""
                  }
                  //onChange={(e) => setMonthInvest(e.target.value)}
                  type="number"
                />
                <TextField
                  select
                  required
                  label="Investment Type"
                  error={errors.investmentType}
                  {...register("investmentType", { required: true })}
                  //onChange={(e) => setType(e.target.value)}
                  helperText={errors.investmentType ? "Field required" : ""}
                >
                  {investmentTypes.map((option, k) => (
                    <MenuItem key={k} value={option.task_name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  required
                  {...register("description", { required: true })}
                  error={errors.description}
                  //onChange={(e) => setDescription(e.target.value)}
                  label="Description"
                  helperText={errors.description ? "Field required" : ""}
                  rows={4}
                />
              </div>
              <div>
                <br />
                  <strong style={{ paddingBottom: "10px" }}>Resume:</strong>
                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setResume(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
              <div>
                <br />
                  <strong style={{ paddingBottom: "10px" }}>Impact:</strong>
                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setImpact(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
              <div>
                <br />
                  <strong style={{ paddingBottom: "10px" }}>Managment:</strong>
                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setManag(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>

              <div>
                <br />
                  <strong style={{ paddingBottom: "10px" }}>Investment plan:</strong>
                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setInvestPlan(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
              <div>
                <br />
                  <strong style={{ paddingBottom: "10px" }}>Risk:</strong>
                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data=""
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setRisk(data)
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>


              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  paddingTop: "50px",
                }}
              >
                {/* <Link to={"/admin/projects"} underline="none">
                  <Button variant="outlined">Cancel</Button>
                </Link> */}
                <Button
                  type="reset"
                  sx={{
                    marginLeft: "15px",
                  }}
                  onClick={() => onSHowProjectForm(!projectForm)}
                  variant="outlined"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  sx={{
                    marginLeft: "15px",
                  }}
                  variant="contained"
                >
                  Add Project
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
      )}
    </div>
  );
}
