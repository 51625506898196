import Parse, { config } from "../config";
import store from "../reducer";
import {
  Transaction,
  Contract,
  InvestmenPlan,
  InvestmentType,
  TransactionType,
  Project,
} from "./Object";


export async function getSingleProject(id) {
  const query = new Parse.Query(Project)
  query.equalTo('project_id', id)
  query.include("investmentType");
  query.descending("createdAt");
  
  const res = await query.first();
  const project =  res && res.toJSON()  || null;
  console.log('donné de id', project)
  return project
}










export async function listProject(page, max) {
  try {
    store.dispatch({ type: "RELOAD", state: true });
    const query = new Parse.Query(Project);
    // if (page !== 0) {
    //   query.skip(page * max);
    // }
    // query.limit(max);
    query.include("investmentType");
    query.descending("createdAt");

    const results = await query.find();

    const projects = results.map((r, i) => r.toJSON());

    store.dispatch({ type: "SAVE_PROJECT", data: projects });
    store.dispatch({ type: "RELOAD", state: false });

    console.log("projects", projects);

    return projects;
  } catch (error) {
    store.dispatch({ type: "RELOAD", state: false });
  }
}

export async function saveProjectPackage(projectPlan) {
  try {
    store.dispatch({ type: "RELOAD", state: true });
    const obj = { ...projectPlan, user_id: Parse.User.current()._getId() };
    const result = await Parse.Cloud.run("saveProjectPackage", obj);
    store.dispatch({ type: "RELOAD", state: false });
    console.log("result result resultresult loadtem", result, obj);
    return result;
  } catch (error) {
    console.log(error);
    // store.dispatch({ type: "RELOAD", state: false });
  }
}
export async function getInvestPlans(project_id) {
  try {
    store.dispatch({ type: "RELOAD", state: true });

    let query = new Parse.Query(Project);
    let project;
    if (project_id) {
      project = await query.get(project_id);
    }

    query = new Parse.Query(InvestmenPlan);
    if (project_id) {
      query.equalTo("project", project);
    } else {
      query.doesNotExist("project");
    }

    const results = await query.find();

    store.dispatch({ type: "RELOAD", state: false });

    return results.map((r) => r.toJSON());
  } catch (error) {
    console.log(error);
    store.dispatch({ type: "RELOAD", state: false });
  }
}
export async function destroyInvestMentPack(pack_id) {
  try {
    store.dispatch({ type: "RELOAD", state: true });

    let query = new Parse.Query(InvestmenPlan);
    const plan = await query.get(pack_id);

    const state = await plan.destroy();

    store.dispatch({ type: "RELOAD", state: false });

    return state;
  } catch (error) {
    console.log(error);
    store.dispatch({ type: "RELOAD", state: false });
  }
}

export async function getHolderForProject(project_id) {
  console.log("getHolderForProject getHolderForProject start");
  try {
    store.dispatch({ type: "RELOAD", state: true });

    const matchesQuery = new Parse.Query(Project);
    const project = await matchesQuery.get(project_id);

    const query = new Parse.Query(Transaction);
    //query.matchesQuery("project", matchesQuery);
    query.equalTo("project", project);
    query.include("userMain");

    const results = await query.find();

    console.log("results results results results", results);

    store.dispatch({ type: "RELOAD", state: false });

    return results.map((r) => r.toJSON());
  } catch (error) {
    console.log("getHolderForProject", error);
    store.dispatch({ type: "RELOAD", state: false });
  }
}

export async function countShareHolder(project_id) {
  try {
    store.dispatch({ type: "RELOAD", state: true });

    const matchesQuery = new Parse.Query(Project);
    const project = await matchesQuery.get(project_id);

    const query = new Parse.Query(Transaction);
    //query.matchesQuery("project", matchesQuery);
    query.equalTo("project", project);
    query.include("userMain");

    let count = await query.count();

    // console.log('results results results results', results)

    store.dispatch({ type: "RELOAD", state: false });

    // return results.map(r =>r.toJSON())
    return count;
  } catch (error) {
    console.log("getHolderForProject", error);
    store.dispatch({ type: "RELOAD", state: false });
  }
}

export async function submitProjectReport(project) {
  try {
    store.dispatch({ type: "RELOAD", state: true });
    const obj = { ...project, user_id: Parse.User.current()._getId() };
    const result = await Parse.Cloud.run("submitProjectRepport", obj);
    store.dispatch({ type: "RELOAD", state: false });
    console.log("result result resultresult loadtem", result, obj);
    return result;
  } catch (error) {
    //store.dispatch({type: "NEED_TO_RELOAD"})
    console.log("error fetchHistory loadtem", error);
  }
}

export async function closeProject(project) {
  try {
    store.dispatch({ type: "RELOAD", state: true });
    const obj = { ...project, user_id: Parse.User.current()._getId() };
    const result = await Parse.Cloud.run("closeProject", obj);
    store.dispatch({ type: "RELOAD", state: false });
    console.log("result result resultresult loadtem", result, obj);
    return result;
  } catch (error) {
    //store.dispatch({type: "NEED_TO_RELOAD"})
    console.log("error fetchHistory loadtem", error);
  }
}

export async function findProjectById(id) {
  try {
    store.dispatch({ type: "RELOAD", state: true });
    const query = new Parse.Query(Project);
    query.include("investmentType");
    query.descending("createdAt");
    const result = await query.get(id);

    store.dispatch({ type: "RELOAD", state: false });

    return result && result.toJSON();
  } catch (error) {
    store.dispatch({ type: "RELOAD", state: false });
  }
}
