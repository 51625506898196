import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {listOfMyTeam} from '../utility'
import {colors} from '../themes'
import { Responsive } from "responsive-react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';


function createData(name, status, level, date) {
  return {
    name,
    status,
    level,
    date
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Account Number',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Account status',
  },
  {
    id: 'level',
    numeric: false,
    disablePadding: false,
    label: 'Level',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Registration date',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ListTeam(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('status');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  //const [myTeam, setTeam] = React.useState([]);
 
  function rangeArray(object){
    let t = []
    object.forEach(element => {
        if(element.subChild){
          if(element.subChild && element.subChild.length > 0){
            const sub = rangeArray(element.subChild)
            t = t.concat(sub)
          }
        }
        t.push(element)
    });
    console.log('t t t tvv', t)
    return t;
  }
 

  const myTeam = useSelector(p => {
    
    const res = rangeArray(p.app.listTeam).map(loc =>{
      const date =  String(new Date(loc.createdAt).toString().split('GMT')[0])
        return {
              name: loc?.account_id +  (loc &&loc.name ? " ["+loc.name+"]" : "" ),
              status: loc?.kycValidated ? "Verified" : "Not Verified",
              level: loc.level,
              date: date
          }

    })
    return res
  })
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = myTeam.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myTeam.length) : 0;
   
  
    // React.useEffect(() =>{
    //     (async()=>{
    //         let datax = await listOfMyTeam()
    //         if(datax){
    //             const data = rangeArray(datax)
    //             const locRow = []
    //             for(let i = 0; i < data.length; i++){
    //                 const loc = data[i]

    //                 //String(loc.createdAt.split('T')[0])
    //                 const date =  String(new Date(loc.createdAt).toString().split('GMT')[0])
    //                 locRow.push(createData(loc?.account_id, loc?.kycValidated ? "Verified" : "Not Verified", loc.level,  date))
    //             }
    //             setTeam(locRow)
    //             console.log('use effect run again', locRow)
    //         }
    //     })()
    // }, [])

    const isMobile = useMediaQuery({ maxWidth: 767 })


  return (
    <Box sx={{ width: '100%' }}> 
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Responsive displayIn={["Laptop", "Tablet"]}>
          <TableContainer>
            <Table
              sx={{ minWidth: 250 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={myTeam.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(myTeam, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.level}</TableCell>
                        <TableCell>{row.date}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Responsive>





        {
          //Mobile view started here ********
        }



        <Responsive displayIn={["Mobile"]}>
          {stableSort(myTeam, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {

              return (
                <CardResponsiveContract
                  row={row}
                  key={index}
                  cle={index}
                />
              );
            })}
          
        </Responsive>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={myTeam.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={isMobile? "Rows:" : "Rows per page:"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}


function CardResponsiveContract({row, cle}){
  return(
    <>
      <br/>
      <Card sx={{ minWidth: 275 }} style={{borderColor: 'red'}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color={colors.primary} gutterBottom>
            {row.name}
          </Typography>
          <Typography variant="body2">
          <i> Account status:</i>{" "+row.status}
          </Typography>
          <Typography variant="body2">
            Level: {" "+row.level}
          </Typography>
          
          <Typography variant="body2">
          <i> Registration date:</i>{" "+row.date}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}