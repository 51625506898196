import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import { useSelector } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import ProjectProgressBar from "../../components/ProjectProgressBar.js";
import { listProject } from "../../utility/project.js";
import './../../css/project-details.css';
import { useParams } from "react-router-dom";
import { Markup } from 'interweave';



// rigth table style
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        marginBottom: "1rem"
    },
    table: {
        minWidth: 100,
    },
}));
async function allProjects() {
    return (listProject())
}



export default function ProjectDetails(props) {
    const classes = useStyles();
    const [projectList, setProjectList] = React.useState([])
    const { id } = useParams()
    const myprojectList = useSelector((p) => p.app?.projects);
    console.log('nouvelle liste de mes projets', myprojectList)



    const singleProject = (project_id) => {
        let project = myprojectList.find((e) => e.project_id === project_id)
        return project
    }
    const project = singleProject(id)
    console.log('info du projet encours', project)


    const DataTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">

                    <TableBody>


                        <StyledTableRow >

                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="right">{project.localisation}</StyledTableCell>

                        </StyledTableRow>
                        <StyledTableRow >

                            <StyledTableCell align="left">Sector</StyledTableCell>
                            <StyledTableCell align="right">{project.activity_sector}</StyledTableCell>

                        </StyledTableRow>
                        <StyledTableRow >

                            <StyledTableCell align="left">Turn over</StyledTableCell>
                            <StyledTableCell align="right">{project.turnover}</StyledTableCell>

                        </StyledTableRow>
                        <StyledTableRow >

                            <StyledTableCell align="left">Employees</StyledTableCell>
                            <StyledTableCell align="right">{project.number_employees}</StyledTableCell>

                        </StyledTableRow>


                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <div>

            <div className="p-details-container" id="projectDetail" v-cloak>
                <div className="p-details-header">


                    {/* <!-- images --> */}
                    <div className="p-details-image">
                        <img src={project.picture.url} alt="projet image" srcset="" width="100%"
                            height="100%" />
                    </div>


                    {/* <!-- details du projet --> */}
                    <div className="p-sommary-details">

                        <div>
                            <h2>{project.name}</h2>
                            <p><span className="pdg"><b>{project.pdg_name}</b></span> &nbsp; &nbsp; {project.investmentType.name}.
                            </p>
                        </div>

                        <div className="bref-summary">
                            <Markup content={project.description} />

                        </div>

                        <div className="gand-info">
                            <ul>
                                <li><span>€ {project.amount}<br /> </span><span>Amount</span></li>
                                <li><span>{project.return_rate}%  <br /></span><span>Interest</span></li>
                                <li><span>{project.month_investment_maturity} <sup>mths</sup> <br /></span><span>Maturity</span></li>
                                <li><span>{project.month_investment_maturity} <sup>mths</sup> <br /> </span><span>Repayments</span></li>
                                <li><span>EUR  <br /></span><span>Currency</span></li>

                            </ul>
                        </div>
                        <div className="progess-bar" >
                            <ProjectProgressBar  progress={
                (
                  (new Date().getTime() -
                    new Date(project.createdAt).getTime()) /
                  (new Date(project.start_date?.iso).getTime() -
                    new Date(project.createdAt).getTime())
                ).toFixed(2) *
                  100 >
                100
                  ? 100
                  : (
                      (new Date().getTime() -
                        new Date(project.createdAt).getTime()) /
                      (new Date(project.start_date?.iso).getTime() -
                        new Date(project.createdAt).getTime())
                    ).toFixed(2) * 100
              }
              project={project} />

                        </div>


                    </div>

                </div>

                {/* <!-- menu bar --> */}
                <div className="p-menu-bar">




                </div>

                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Summary" {...a11yProps(0)} />
                            <Tab label="Impact" {...a11yProps(1)} />
                            <Tab label="Financial Information" {...a11yProps(2)} />
                            <Tab label="Risks" {...a11yProps(3)} />
                            <Tab label="Management" {...a11yProps(4)} />
                            <Tab label="Documents" {...a11yProps(5)} />
                        </Tabs>
                    </AppBar>





                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >

                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Summary</h1>
                                    <Typography variant='body'>

                                        <Markup content={project.resume} />

                                    </Typography>



                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Impact</h1>
                                    <Typography variant='body'>
                                        <Markup content={project.impact} />


                                    </Typography>



                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>

                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Financial Information</h1>
                                    <Typography variant='body'>
                                    <Markup content={project.financial_plan} />
                                    </Typography>


                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>

                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Risks</h1>

                                    <Typography variant='body'>
                                    <Markup content={project.project_risks} />

                                    </Typography>



                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>

                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Management</h1>
                                    <Typography variant='body'>
                                    <Markup content={project.managment_description} />

                                    </Typography>




                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>

                        <TabPanel value={value} index={5} dir={theme.direction}>

                            <div id="summary" className="description">
                                <div className="text">

                                    <h1>Documents</h1>
                                    <Typography variant='body'>
                                        no documents
                                    </Typography>



                                </div>
                                <div className="compagnie-detail">
                                    <DataTable />
                                </div>

                            </div>
                        </TabPanel>

                    </SwipeableViews>
                </div>






















































            </div>

        </div>


    )
}