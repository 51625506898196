import React, { Component } from 'react';
import {

  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
} from 'react-share';

import './Share.css';


  class Share extends Component {
    render() {
      return (
        <div className="Demo__container">
          <div className="Demo__some-network">
            <FacebookShareButton
              url={this.props.shareUrl}
              quote={this.props.title}
              className="Demo__some-network__share-button">
              <FacebookIcon
                size={32}
                round />
            </FacebookShareButton>
  
          </div>
  
          <div className="Demo__some-network">
            <TwitterShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              className="Demo__some-network__share-button">
              <TwitterIcon
                size={32}
                round />
            </TwitterShareButton>
  
            <div className="Demo__some-network__share-count">
              &nbsp;
            </div>
          </div>
  
          <div className="Demo__some-network">
            <TelegramShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              className="Demo__some-network__share-button">
              <TelegramIcon size={32} round />
            </TelegramShareButton>
  
            <div className="Demo__some-network__share-count">
              &nbsp;
            </div>
          </div>
  
          <div className="Demo__some-network">
            <WhatsappShareButton
              url={this.props.notConsiderUrl ? this.props.notConsiderUrl : this.props.shareUrl}
              title={this.props.title}
              separator={this.props.separator ? this.props.separator : " : "}
              className="Demo__some-network__share-button">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
  
            <div className="Demo__some-network__share-count">
              &nbsp;
            </div>
          </div>
  
          <div className="Demo__some-network">
            <LinkedinShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              windowWidth={750}
              windowHeight={600}
              className="Demo__some-network__share-button">
              <LinkedinIcon
                size={32}
                round />
            </LinkedinShareButton>
  
          </div>
  
  
          <div className="Demo__some-network">
            <VKShareButton
              url={this.props.shareUrl}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button">
              <VKIcon
                size={32}
                round />
            </VKShareButton>
  
          </div>
  
          <div className="Demo__some-network">
            <OKShareButton
              url={this.props.shareUrl}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button">
              <OKIcon
                size={32}
                round />
            </OKShareButton>
  
          </div>
  
          <div className="Demo__some-network">
            <RedditShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button">
              <RedditIcon
                size={32}
                round />
            </RedditShareButton>
  
          </div>
  
          <div className="Demo__some-network">
            <LivejournalShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              description={this.props.shareUrl}
              className="Demo__some-network__share-button"
            >
              <LivejournalIcon size={32} round />
            </LivejournalShareButton>
          </div>
  
          <div className="Demo__some-network">
            <MailruShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              className="Demo__some-network__share-button">
              <MailruIcon
                size={32}
                round />
            </MailruShareButton>
          </div>
  
          <div className="Demo__some-network">
            <EmailShareButton
              url={this.props.shareUrl}
              subject={this.props.title}
              body="body"
              className="Demo__some-network__share-button">
              <EmailIcon
                size={32}
                round />
            </EmailShareButton>
          </div>
          <div className="Demo__some-network">
            <ViberShareButton
              url={this.props.shareUrl}
              title={this.props.title}
              body="body"
              className="Demo__some-network__share-button">
              <ViberIcon
                size={32}
                round />
            </ViberShareButton>
          </div>
        </div>
      );
    }
  }
  
  export default Share;