import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {colors, stylesMain} from '../themes'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { WEBSITE_URL } from '../config'; 
import { useSelector } from "react-redux";

import {processPayment, WAIT} from '../utility'



const labelCheck = { inputProps: { 'aria-label': 'Switch demo' } };
export function InvestModal(props){

    const [packNumber, setNumberPack] = React.useState(1)
    const [payment, setPayment] = React.useState("one time")
    const [loading, setLoanding] = React.useState(false)
    const [flashType, setFlasType] = React.useState("success")
    const [message, setFlasMessage] = React.useState("")
    const [showFlass, setShowFlash] = React.useState(false)
    const [showlik, setShowLink] = React.useState(false)
    const [acceptCondition, setAccept] = React.useState(false)
    const proposer = useSelector(p => p.app.proposer)
    const handleCheckbox = async (event, isChecked) => {
      setAccept(isChecked)
      console.log('event, isChecked, value', event, isChecked)
  
    }

    const onProcess = async() => {
        setLoanding(true)
        setShowFlash(false)
        setShowLink(false)
        console.log('props.pack props.pack props.pack', props.pack)
        if(packNumber < 0){
          setFlasType("error")
          setLoanding(false)
          setShowFlash(true)
          setFlasMessage("The number of package must be gratter than 0")
          return 
        }
        if(!acceptCondition){
          setFlasType("error")
          setLoanding(false)
          setShowFlash(true)
          setFlasMessage("You must acept terms and conditions !")
          return 
        }
        const res = await processPayment({payment: payment, packNumber: packNumber, task_name: props.pack.task_name, project_id: props?.pack?.object?.project?.objectId})
        console.log('res res res resv', res)
        if(res && res.error){
          setFlasType("error") 
          if(res && res.account_not_verified){
            setShowLink(true)
          }
        }else{
          setFlasType("success")
          WAIT({function: closeAndReload});
        }
        setFlasMessage(res&&res.message || "Undefined message")
        setLoanding(false)
        setShowFlash(true)
    }
    
    const closeAndReload = () => {
      console.log('after time out');
      hideDialog(true)
     }

    function hideDialog(reload){
        setLoanding(false)
        setShowFlash(false)
        props.hideDialog(reload)
    }

    return (
        <Dialog
          open={props.open}
          onClose={()=>hideDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{props.pack.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {showFlass&&
              <Alert severity={flashType}>
                {message}
                {showlik&& <a href={WEBSITE_URL+"/update-infos"+(proposer&&proposer.userSecure ? "/"+proposer.userSecure.account_id : "")} target="_blank">Verify</a> }
              </Alert>}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              onChange={(e) => setNumberPack(e.target.value)}
              label="Number of pack "
              type="number"
              value={packNumber}
              fullWidth
            />
            
            <FormControl component="fieldset" style={{marginTop: "20px"}}>
                <FormLabel component="legend">The payment terms:</FormLabel>
                <RadioGroup row aria-label="The payment terms"  defaultValue="one time" name="row-radio-buttons-group" onChange={(e) =>setPayment(e.target.value)}>
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    <FormControlLabel value="one time" control={<Radio />} label="One time" />
                </RadioGroup>
            </FormControl>

            <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
              <Checkbox onChange={handleCheckbox} {...labelCheck} style={{color: colors.primary}} />
              <a href={WEBSITE_URL+"/privacy-policy"} target="_blank">
                Accept the Terms & Conditions
              </a>
          </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>hideDialog()} color="primary">
              Cancel
            </Button>
            <Button onClick={()=>onProcess()} color="primary" disabled={loading}>
                {loading && <CircularProgress size={20} />}
                Process
            </Button>
          </DialogActions>
        </Dialog>
    )
}