import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {colors, stylesMain} from '../themes'


const labelCheck = { inputProps: { 'aria-label': 'Switch demo' } };

export default function OnReject({row, onReject}) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handeChange = () => {
    setOpen(false);
    setComment("")
    onReject(row, comment)
  };



  return (
    <div>
      <Button 
       onClick={handleClickOpen}
       style={{backgroundColor: colors.primary, color: 'white', ...styles.customButton, marginTop: '5px'}}
      >
        {"Reject"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Reject user: {row.name}</DialogTitle> 
        <DialogContent>
            <TextField 
                id="outlined-basic" 
                multiline
                minRows={2} 
                label="Texto" 
                variant="outlined"
                fullWidth 
                style={{width: '100%', marginTop: 15}} 
                value={comment}
                onChange={(e)=>setComment(e.target.value)}
                />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handeChange}>{"Reject"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  card: {
      backgroundColor: colors.secondary, 
      textAlign: 'center',
      // width: "200px"
      
  },
  button: {
      textDecoration: 'none'
  },
  customButton: {
    fontSize: "10px",
      height: "30px",
      width: "80%"
  }
}