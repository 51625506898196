import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {getMainAccountInfos, onProcessMainRechargeRIA, verificationOTP, onUpdateRIaValue, fetchHistorieRiaValue} from '../../utility'
import { Title } from 'react-admin';
import Button from '@mui/material/Button';
import Snack from '../../components/Snack'
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@material-ui/core/Box';
import UpdateIcon from '@mui/icons-material/Update';
import Grid from '@material-ui/core/Grid';
import HistoricRIaUpdate from "./HistoricRIaUpdate"

export function MainAccount(){
    const [ria_balance, setRiaBal] = React.useState(null)
    const [investment_account, setInvestMent] = React.useState(null)
    const [form, showForm] = React.useState(false)
    const [otp, showOtp] = React.useState(false)
    const [new_recharge_amount, setR] = React.useState(null)
    const [otpCode, setOtpCode] = React.useState(null)
    const [objectId, setObjectID] = React.useState(false)
    const [openSnack, setOpenSnack] = React.useState(false)
    const [messageSkack, setMessageSnack] = React.useState("")
    const [type, setTypeSnack] = React.useState("error")
    const [reason, setReason] = React.useState("")
    const [ria_value, setRiaValue] = React.useState(0)
    const [ria_value_reason, setRiaValueReason] = React.useState("")
    const [update_visible, showUpdate] = React.useState(false)
    const [update_value, showUpdateValue] = React.useState(0)
    const [histories, setHistories] = React.useState([])

    const onConfirm = async ()=>{
        setOpenSnack(false)
        if(!otpCode||otpCode&&otpCode.trim() === ""){
            setMessageSnack('OTP is required ')
            setOpenSnack(true)
            return
        }
        const res = await verificationOTP({new_recharge_amount, objectId, otpCode})
        console.log('result result result', res)
        if(res && !res.error){
            fetchDAT()
            showOtp(false)
            showForm(false)
            setOtpCode(null)
            setR(null)
            setTypeSnack('success')
            setMessageSnack(res.message)
            setOpenSnack(true)
        }else{
            setTypeSnack('error')
            setMessageSnack(res.message || 'Unknow error !')
            setOpenSnack(true)
        }
    }
    const onProcess = async ()=>{
        setOpenSnack(false)
        if(!new_recharge_amount || new_recharge_amount <0){
            setTypeSnack('error')
            setMessageSnack('Invalid amount !')
            setOpenSnack(true)
            return
        }
        if(!reason || reason&&reason.trim() ===""){
            setTypeSnack('error')
            setMessageSnack('Reason not provided !')
            setOpenSnack(true)
            return
        }
        const res = await onProcessMainRechargeRIA(new_recharge_amount, reason)
        if(res && !res.error){
            showOtp(true)
            setObjectID(res.id)
        }else{
            setTypeSnack('error')
            setMessageSnack(res.message || 'Unknow error !')
            setOpenSnack(true)
        }
        console.log('res res res res res', res)
    }
    function fetchDAT(){
        (async ()=>{
            const mainAcc = await getMainAccountInfos()
            setRiaBal(mainAcc?.data)
            setInvestMent(mainAcc?.investment_account)
            setRiaValue(mainAcc?.ria_value)
            showUpdateValue(mainAcc?.ria_value)
            console.log('count count count count', mainAcc)
        })()
    }
    React.useEffect(()=>{  
        fetchDAT()
        fetchHistories()
    }, [])

    async function fetchHistories(){
        const result = await fetchHistorieRiaValue()
        if(result){
            setHistories(result)
        }
        console.log('fetchHistories fetchHistories fetchHistories', result)
    }
    async function onUpdateRia(){
        try {
            if(!update_value || isNaN(parseInt(update_value))){
                return window.alert('Undefined ria value')
            }
            const obx  = {
                reason: ria_value_reason, 
                ria_value: update_value
            }
            const result = await onUpdateRIaValue(obx)
            setRiaBal(result?.data)
            setInvestMent(result?.investment_account)
            setRiaValue(result?.ria_value)
            showUpdateValue(result?.ria_value)
            setRiaValueReason('')
            fetchHistories()
            showUpdate(false)
            console.log('value vaue result', result, obx)
        } catch (error) {
            console.log('errrror update', error)
        }
    }
    async function onRolback(obj){
        if(!window.confirm('Rollback to this date ?'))return
        try {
            const obx  = {
                reason: "Rollback to " +obj.date, 
                ria_value: obj.amount
            }
            const result = await onUpdateRIaValue(obx)
            setRiaBal(result?.data)
            setInvestMent(result?.investment_account)
            setRiaValue(result?.ria_value)
            showUpdateValue(result?.ria_value)
            fetchHistories()
            console.log('value vaue result', result, obx)
        } catch (error) {
            console.log('errrror update', error)
        }
    }

    return(
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}  xs={12}> 
                        <Card>
                            <CardContent>
                                <h4>Investment Account Balance:{" "+(investment_account? investment_account: "")}</h4>
                                <h4>MIA Balance:{" "+(ria_balance? ria_balance: "")}</h4> 
                                
                                {!form &&
                                    <Button variant="outlined" onClick={()=>showForm(true)}>New Release</Button>
                                }
                                {form &&
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}  xs={12}>  
                                                <TextField  
                                                    label="Release value" 
                                                    type="number" 
                                                    disabled={otp} 
                                                    value={new_recharge_amount} 
                                                    onChange={(e)=>setR(e.target.value)} 
                                                    variant="outlined" 
                                                    fullWidth
                                                />
                                                <div style={{height: '20px'}}/>
                                                <TextField  
                                                    label="Reason of release" 
                                                    disabled={otp} 
                                                    multiline
                                                    fullWidth
                                                    value={reason} 
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    onChange={(e)=>setReason(e.target.value)} variant="outlined" 
                                                />
                                                <div style={{height: '20px'}}/>
                                                
                                                {otp && <TextField type="text" label="Otp code" value={otpCode} onChange={(e)=>setOtpCode(e.target.value)} variant="outlined"/>}
                                                <div style={{height: '20px'}}/>
                                                {!otp &&<Button variant="outlined" onClick={()=>onProcess(true)}>Process</Button>}
                                                {otp &&<Button variant="outlined" onClick={()=>onConfirm()}>Confirm</Button>}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={12}  xs={12}> 
                        <Card>
                            <CardContent>
                                <div style={{display: 'flex'}}>
                                    
                                    <span>
                                        Ria Value: {" "+(ria_value? ria_value: "")}
                                    </span> 
                                    <span>
                                        <UpdateIcon onClick={()=>showUpdate(!update_visible)} style={{marginTop: "-5px", marginLeft: "10px"}} />
                                    </span>
                                </div>
                                {update_visible &&
                                    <div style={{paddingBottom: '30px', paddingTop: '10px'}}>
                                        <TextField  
                                            label="New Ria value" 
                                            type="number" 
                                            value={update_value} 
                                            onChange={(e)=>showUpdateValue(e.target.value)} 
                                            variant="outlined" 
                                            style={{width: '300px'}}
                                            fullWidth
                                        />
                                        <div style={{height: '20px'}}/>
                                        <TextField  
                                            label="Reason of operation" 
                                            multiline
                                            fullWidth
                                            value={ria_value_reason} 
                                            aria-label="minimum height"
                                            minRows={2}
                                            style={{width: '300px'}}
                                            onChange={(e)=>setRiaValueReason(e.target.value)} variant="outlined" 
                                        />
                                        <br/>
                                        <Button style={{marginTop: '20px'}} variant="outlined" onClick={()=>onUpdateRia()}>Update</Button>
                                    </div>
                                }

                                <HistoricRIaUpdate rows={histories} onRolback={onRolback}/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Snack open={openSnack} message={messageSkack} muteSnack={setOpenSnack} type={type}/>
        </div>
    )
}