export const packagesLocale =  [
    {
        name: "A1 - Starter",
        task_name: "a1_starter",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Yes	Any missed payout will be added to the next payment cycle.",
        t5: "This is not available for A- formula",
        t6: "This is not available for A- formula",
        t7: "This is the monthly cash out if you opt for 6x monthly cash-out terms.",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "100x",
        v2: "20.02%",
        v3: "12",
        v4: "Yes",
        v5: "Yes",
        v6: "No",
        v7: "No",
        v8: "113.76",
        v9: "120.02"
    },
    {
        name: "A2 - Standard",
        task_name: "a2_standard",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "This is not available for A- formula",
        t6: "This is not available for A- formula",
        t7: "This is the monthly cash out if you opt for 6x monthly cash-out terms",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "500x",
        v2: "20.89%",
        v3: "12",
        v4: "Yes",
        v5: "Yes",
        v6: "No",
        v7: "No",
        v8: "95.28",
        v9: "604.49"
    },
    {
        name: "A3 - Premium",
        task_name: "a3_premium",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "This is not available for A- formula",
        t6: "This is not available for A- formula",
        t7: "This is the monthly cash out if you opt for 6x monthly cash-out terms",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "1000",
        v2: "21.08%",
        v3: "12",
        v4: "Yes",
        v5: "Yes",
        v6: "No",
        v7: "No",
        v8: "95.28",
        v9: "1210.83"
    },
    {
        name: "B1 - Starter",
        task_name: "b1_starter",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "This is not applicable for B formula",
        t7: "This is the monthly cash out if you opt from 6th-18th month.",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "500x",
        v2: "21.50%",
        v3: "18",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "No",
        v8: "50.13",
        v9: "607.51"
    },
    {
        name: "B2 - Standard",
        task_name: "b2_standard",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "This is not applicable for B formula",
        t7: "This is the monthly cash out if you opt from 6th-18th month.",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "1000x",
        v2: "22.10",
        v3: "18",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "No",
        v8: "100.70",
        v9: "1221.02"
    },
    {
        name: "B3 - Premium",
        task_name: "b3_premium",
        t0: "This is a number of RIA you need to invest in the corresponding formula.",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "This is not applicable for B formula",
        t7: "This is the monthly cash out if you opt from 6th-18th month.",
        t8: "This is the monthly cash out if you opt for 1x monthly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "2500x",
        v2: "22.78",
        v3: "18",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "No",
        v8: "252.75",
        v9: "3069.53"
    },
    {
        name: "C1 - Starter",
        task_name: "c1_starter",
        t0: "This is a number of RIA you need to invest in the corresponding formula..",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "You are entitled to convert your RIA into ordinary shares, provided conditions set by the BOD are met.",
        t7: "This is the monthly cash out if you opt for 12x monthly cash-out terms.",
        t8: "This is the monthly cash out if you opt for 1x yearly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "1000x",
        v2: "23.05%",
        v3: "24",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "Yes",
        v8: "50.13",
        v9: "607.51"
    },
    {
        name: "C2 - Standard",
        task_name: "c2_standard",
        t0: "This is a number of RIA you need to invest in the corresponding formula..",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "You are entitled to convert your RIA into ordinary shares, provided conditions set by the BOD are met.",
        t7: "This is the monthly cash out if you opt for 12x monthly cash-out terms.",
        t8: "This is the monthly cash out if you opt for 1x yearly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "1000x",
        v2: "23.05%",
        v3: "24",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "Yes",
        v8: "50.13",
        v9: "607.51"
    },
    {
        name: "C3 - Premium",
        task_name: "c3_premium",
        t0: "This is a number of RIA you need to invest in the corresponding formula..",
        t1: "To annual return of the investment.",
        t2: "Duration of this investment contract.",
        t3: "Your profit is paid before any ordinary shareholder.",
        t4: "Any missed payout will be added to the next payment cycle.",
        t5: "You earn your ROI or that of ordinary shareholder, which one is higher!",
        t6: "You are entitled to convert your RIA into ordinary shares, provided conditions set by the BOD are met.",
        t7: "This is the monthly cash out if you opt for 12x monthly cash-out terms.",
        t8: "This is the monthly cash out if you opt for 1x yearly cash-out term.",
        t10: "Payout sent in your payout account each week",
        v1: "2500x",
        v2: "24.03",
        v3: "24",
        v4: "Yes",
        v5: "Yes",
        v6: "Yes",
        v7: "Yes",
        v8: "100.70",
        v9: "1221.02"
    },
]