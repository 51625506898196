// in src/Dashboard.js
import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { CreateButton } from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { colors } from "../themes";
import DialogTopUp from "../components/DialogTopUp";
import DialogTransfert from "../components/DialogTransfert";
import PackRIA from "../components/PackRIA";
import InviteFriend from "../components/InviteFriend";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import ContractTable from "../components/ContractTable";
import { useDispatch } from "react-redux";
import { refleshDATASET } from "../utility";
import Logo from "../layout/Logo";
import { getInvestPlans, listProject } from "../utility/project";
import ProjectCard from "../components/ProjectCard";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";

const groupBy = (array) => {
  let arrays = [];

  array.forEach((pro) => {
    if (isProjectLiked(pro.objectId)) {
      arrays.push(pro);
      array = array.filter((project) => project.objectId !== pro.objectId);
    }
  });

  return arrays.concat(array);
};

const isProjectLiked = (id) => {
  let projectLiked = localStorage.getItem("MCTI_ProjectLike");
  if (projectLiked) {
    let like = projectLiked.split(",");
    let index = like.indexOf(id);
    return index != -1;
  }
};

const CARD = ({ title, value, subvalue, title2 }) => {
  return (
    <Card style={styles.card}>
      <CardContent>
        {title}
        <p style={{ fontWeight: "bold", fontSize: 20 }}>{value || 0}</p>
        {title2 && (
          <>
            {title2}
            <p style={{ fontWeight: "bold", fontSize: 17 }}>{subvalue || 0}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};
const subDashLabel = [];
export default (props) => {
  const [openDialogPopUp, setOpenDialogPopUp] = React.useState(false);
  const [openPackRIA, setOpenPackRIA] = React.useState(false);
  const [openTransfert, setOpenTransfert] = React.useState(false);
  const [openInviteFriend, setOpenInviteFriend] = React.useState(false);

  const [packs, setPackToInvest] = React.useState(null);
  const currentUser = useSelector((p) => p.app?.currentUser);


  // use all project
  const projectList = useSelector((p) => p.app?.projects);
  

  
  const contacts = useSelector((p) => p.app?.contacts);
  console.log('contacts contacts contacts contacts', contacts)
  // const [projects, setProjects] = React.useState(projectList);
  const [status, setStatus] = React.useState("All");
  const projects = groupBy(projectList, "objectId");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [numberItem, setNumberItem] = React.useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // React.useEffect(() => {
  //   setProjects(projectList);
  // }, []);

  /*
  const filterByStatus = (status) => {
    switch (status) {
      case "Closed":
        setProjects(projectList.filter((project) => project.closed));
        break;

      case "Upcoming":
        setProjects(
          projectList.filter(
            (project) =>
              new Date(project.start_date?.iso).getTime() > new Date().getTime()
          )
        );
        break;

      case "Active":
        setProjects(
          projectList.filter(
            (project) =>
              new Date(project.start_date?.iso).getTime() <=
              new Date().getTime()
          )
        );
        break;

      default:
        setProjects(projectList);
        break;
    }
  };
  */

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function calculTabData(page, max) {
    const projects = await listProject();

    console.log("projects projects", projects);
  }

  React.useEffect(() => {
    /* (async () => {
      await calculTabData(0, 5);
    })(); */

    console.log("mes contrats", contacts);
  }, []);

  if (window.location.href.split("/#/")[1]) {
    props.history.push("/");
  }

  const setOpenDialogPopUp1 = async (arg1, arg2) => {
    setOpenDialogPopUp(arg1);
    console.log("refleshDATASET before");
    if (arg2) {
      await refleshDATASET();
    }
  };
  const setOpenTransfert1 = async (arg1, arg2) => {
    setOpenTransfert(arg1);
    if (arg2) {
      await refleshDATASET();
    }
  };
  const setOpenPackRIA1 = async (arg1, arg2) => {
    setOpenPackRIA(arg1);
    setPackToInvest(null);
    console.log("refleshDATASET before");
    if (arg2) {
      await refleshDATASET();
    }
  };

  async function onStartInvestment(project_id) {
    setOpenPackRIA(!openPackRIA);
    const pack = await getInvestPlans(project_id);
    setPackToInvest(pack);
    console.log("***********////////////", pack);
  }

  return (
    <>
      <Title title={<Logo title="DASHBOARD" />} />
      <Box
        sx={{ flexGrow: 1 }}
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <CARD
              title2="Free Balance"
              title="Balance"
              value={currentUser?.userSecure?.balance.toFixed(2)}
              subvalue={currentUser?.userSecure?.balance_free.toFixed(2)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD
              title2="Payout turnover"
              title="Payout Balance"
              value={currentUser?.userSecure?.payout_balance.toFixed(2)}
              subvalue={currentUser?.userSecure?.payout_turnover.toFixed(2)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD
              title2="RECO Turnover"
              title="RECO Balance"
              value={currentUser?.userSecure?.reco_balance.toFixed(2)}
              subvalue={currentUser?.userSecure?.reco_turnover.toFixed(2)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD
              title2="Active investment"
              title="Investment Turnover"
              value={currentUser?.userSecure?.investment_turnover}
              subvalue={currentUser?.userSecure?.active_investment}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>

          <Grid item md={3} xs={12}>
            <CARD
              title="Commissions"
              value={currentUser?.userSecure?.commissions}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD title="Bonus" value={currentUser?.userSecure?.bonus} />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD
              title="Personal Turnover"
              value={currentUser?.userSecure?.personal_turnover}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <CARD
              title="Team Turnover"
              value={currentUser?.userSecure?.team_turnover}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Divider />
          </Grid>

          {/* <Grid item md={1} xs={1}></Grid> */}
          <Grid item md={2} xs={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: colors.primary,
                color: "white",
                ...styles.customButton,
              }}
              onClick={() => setOpenDialogPopUp(!openDialogPopUp)}
            >
              Top-up
            </Button>
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: colors.primary,
                color: "white",
                ...styles.customButton,
              }}
              onClick={() => onStartInvestment(null)}
            >
              Start investment
            </Button>
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: colors.primary,
                color: "white",
                ...styles.customButton,
              }}
              onClick={() => setOpenTransfert(!openTransfert)}
            >
              Transfer
            </Button>
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              disabled
              variant="contained"
              style={{
                backgroundColor: "#AC83DC",
                color: "white",
                ...styles.customButton,
              }}
            >
              Cash-out
            </Button>
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              variant="contained"
              style={{
                backgroundColor: colors.primary,
                color: "white",
                ...styles.customButton,
              }}
              onClick={() => setOpenInviteFriend(!openInviteFriend)}
            >
              Invite Friends
            </Button>
          </Grid>

          <Grid item md={12}>
            <Divider />
          </Grid>

          <Grid item md={12} xs={12}></Grid>
          <Grid item md={12} xs={12}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h5"
                  component="div"
                  style={{ color: colors.primary, textAlign: "center" }}
                >
                  {"Investment"}
                </Typography>
                <ContractTable contracts={contacts} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} xs={12}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h5"
                  component="div"
                  style={{ color: colors.primary, textAlign: "center" }}
                >
                  {"Project"}
                </Typography>

                <FormControl
                  variant="standard"
                  sx={{ m: 1, width: 400 }}
                  style={{
                    width: "300px",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                    alignItems: "right",
                  }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Filter By Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Filter"
                  >
                    <MenuItem value={"All"} selected={true}>
                      All
                    </MenuItem>
                    <MenuItem value={"Upcoming"}>Upcoming</MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"MyProject"}> My Project</MenuItem>

                    <MenuItem value={"Closed"}>Closed</MenuItem>
                  </Select>
                </FormControl>

                <Grid container style={{ marginTop: "10px" }} spacing={2}>
                  {projects
                    .filter((project) => {
                      if (status === "Closed") {
                        return project.closed;
                      }
                      if (status === "Upcoming") {
                        return (
                          new Date(project.start_date?.iso).getTime() >
                          new Date().getTime()
                        );
                      }
                      if (status === "MyProject") {
                        return contacts.find(element => project.objectId === element.project_id)
                      }

                      if (status === "Active") {
                        return (
                          new Date(project.start_date?.iso).getTime() <=
                          new Date().getTime()
                        );
                      } else return true;
                    })
                    .map((p, i) => (
                      <Grid item xs={12} md={12} sm={6} lg={6} key={i}>
                        <ProjectCard
                          project={p}
                          liked={isProjectLiked(p.objectId)}
                          onInvest={onStartInvestment}
                          isAdmin={false}
                          dontShowIcon
                        />
                      </Grid>
                    ))}
                </Grid>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={projectList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={isMobile ? "Rows:" : "Rows per page:"}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <DialogTopUp
        onChangeState={(_v, roload) => setOpenDialogPopUp1(_v, roload)}
        open={openDialogPopUp}
      />
      <DialogTransfert
        onChangeState={(_v, roload) => setOpenTransfert1(_v, roload)}
        open={openTransfert}
      />
      <PackRIA
        onChangeState={(_v, roload) => setOpenPackRIA1(_v, roload)}
        open={openPackRIA}
        project_id={false}
        packs={packs}
      />
      <InviteFriend
        onChangeState={(_v) => setOpenInviteFriend(_v)}
        open={openInviteFriend}
      />
    </>
  );
};

const styles = {
  card: {
    backgroundColor: colors.secondary,
    textAlign: "center",
    // width: "200px"
  },
  button: {
    textDecoration: "none",
  },
  customButton: {
    height: "50px",
    width: "100%",
  },
};
