import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { saveKey, idGenerateFileName, saveParseFile } from '../utility';
import Box from '@material-ui/core/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { serverAuthType } from 'parse';
import {colors} from '../themes'
import ReactHoverObserver from 'react-hover-observer';
import { useMediaQuery } from 'react-responsive'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';


export function InputAdornments(props) {
  
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [value, setValue] = React.useState("sd")
  const [editing, setEditing] = React.useState(false)
  const [type, setType] = React.useState("text")
 
  React.useEffect(()=>{
    setValue(props.defaultValue)
  }, [])

  const onPressButIcon = async () => {
    setEditing(!editing)
    setType("text")
    let v = value
    console.log('stated save', v)
    if(props.date){
      v = new Date(v)
    }
    await saveKey(props.cle, v)
  };

  async function onKeyPress(e){
    if (!e) e = window.event;
    const code = e.code || e.key;
    if (code == 'Enter'){
      await onPressButIcon()
    }
  }

  const onChangeText = (event) => {
    if(props.date){
      setType("date")
    }
    setValue(event.target.value)
  };

  function onBlur(e){
    console.log('onBlur run')
    onPressButIcon()
  }
  function onHoverChanged(e){
    console.log('onBlur run', e)
    if(!e.isHovering){
      onPressButIcon()
    }
  }
  const currentUser = useSelector(p => p.app.currentUser) || {}
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 8, width: '100%', marginTop: '10px' },
      }}
      
      noValidate
      autoComplete="off"
    >
      <ListItem div alignItems="flex-start" >
        <div style={{display: 'flex', width: "100%"}}>
          <span style={{width: "40%"}}>
          {props.label}
          </span>

          <span>
            {props.defaultValue}
          </span>
        </div>
      </ListItem>
      <Divider />
      
    </Box>
    
  );
}


export function DocType(props){
  const handleChange = async (e) => {
    await saveKey(props.cle, e.target.value)
    props.handleChangeDocType(e.target.value)
  };

  const currentUser = useSelector(p => p.app.currentUser)  || {}
  React.useEffect(() => {
    props.handleChangeDocType(props.defaultValue)
   }, [])

  return(
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 8, width: '100%', marginTop: '10px' },
      }}
      
      noValidate
      autoComplete="off"
    >
      <TextField
          id="outlined-select-currency"
          select
          label="Document type"
          value={props.defaultValue}
          disabled={currentUser?.kycSubmitted || currentUser?.userSecure?.kycValidated}
          onChange={handleChange}
      >
        <MenuItem value={"driving licence"}>Driver licence</MenuItem>
        <MenuItem value={"passport"}>Passport</MenuItem>
        <MenuItem value={"id card"}>ID card</MenuItem>
      </TextField>  
    </Box>
    
  )
}
export function File(props){
  const currentUser = useSelector(p => p.app.currentUser)  || {}

  const [name, setName] = React.useState(props.label)
  
  const handleChange = async (e) => {
    console.log('e**************************', e)
    const file = e.target.files[0];
    setName(file.name)
    const normalizeName = await  idGenerateFileName(props.prefix, file.name)
    console.log('parseFile parseFile parseFile before', normalizeName)
    const parseFile = await saveParseFile(normalizeName, file)
    console.log('savec key key key', parseFile)
    await saveKey(props.cle, parseFile)
  }
  const click =  () => {
    window.open(
      props.OldUrl,
      '_blank' 
    );
  }
  
  return(
    <Box sx={{ display: 'flex', justifyContent: "center" , alignItems: 'center', paddingRight: '50px'}}>
      <div>
        <img src={props.OldUrl} alt="file ID" width="100" height="100"/>
      </div>
    </Box>
    
  )
}
export function Country(props){
  const currentUser = useSelector(p => p.app.currentUser)  || {}
  const isMobile = useMediaQuery({ maxWidth: 767 })
  React.useEffect(() => {
    window.$("#"+props.cle).countrySelect({defaultCountry: props.defaultValue});
   }, [])

  const handleChange = async (e) => {
    if(window.$("#"+props.cle+"_code").val()){
      await saveKey(props.cle,  window.$("#"+props.cle+"_code").val())
    }
  };
  async function onKeyPress(e){
    if (!e) e = window.event;
    const code = e.code || e.key;
    if (code == 'Enter'){
      await handleChange()
    }
  }
  async function onPressButIcon(e){
    console.log('press butIcon')
      await handleChange()
    
  }

  return(
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 8, width: '100%', marginTop: '10px' },
      }}
      
      noValidate
      autoComplete="off"
    >

      <TextField 
        label="Outlined secondary" 
        color="secondary" focused 
        disabled={true}
        id={props.cle}
        label={props.label}
      />
      <input type="hidden" id={props.cle+"_code"}/>
      
    </Box>
    
  )
}

const styles = {
    border: '1px solid #8C52FF',
    alignItems: "center",
    justifyContent: 'center',
    display: 'inline-block',
    padding: '6px 12px',
    cursor: 'pointer',
    width: '100%',
    borderRadius: '10px',
}
