import React, { useState, useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { Admin, Resource } from "react-admin";
import AuthService from "./service/Auth";
import store from "./reducer";
import { provider } from "./reducer/provider";
import Dashboard from "./screens/Dashboard";
import { useDispatch } from "react-redux";
import customRoutes from "./routes";
import { Layout } from "./layout";
import { useHistory } from "react-router-dom";
import { config } from "./config";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Parse from "./config";
import {
  refleshDATASET,
  saveLastLoaction,
  getContract,
  getTransaction,
  countTransactionUser,
  listOfMyTeam,
  listPack,
  getSporsorId,
} from "./utility";
import "./App.css";
import Snack2 from "./components/Snack2";

import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { listProject } from "./utility/project";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8C52FF",
    },
    secondary: {
      main: "#00fff0",
    },
  },
});

const _MctIHome_ = (props) => {
  console.log(
    "*******************************_MctIHome_********************************"
  );
  const [user, setU] = useState(null);
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  const _user = new Parse.User();
  const need_to_reload = useSelector((a) => a.app.need_to_reload);

  function initApp() {
    store.dispatch({ type: "SESSION_RESET" });
    const _kc = window.Keycloak(config);
    (async function () {
      setOpen(true);

      let _kcObject = await AuthService.checkKeycloakStatus(_kc);
      let authenticated = false;

      if (_kcObject && _kcObject.authenticated) {
        authenticated = _kcObject.authenticated;
      }

      if (!authenticated) {
        localStorage.setItem("authenticated", false);

        _kcObject = await AuthService.initKeycloak(_kc);
      }

      dispatch({ _kc: _kc, type: "_KC_OBJECT" });
      let authData = {
        keycloak: {
          access_token: _kcObject.token,
          id: _kcObject.subject,
          roles: _kcObject?.idTokenParsed?.roles,
          groups: _kcObject?.idTokenParsed?.groups,
        },
      };
      setU(_kcObject);
      let res;
      if (Parse.User.current()) {
        await Parse.User.logOut();
      }

      res = await _user._linkWith("keycloak", {
        authData: authData["keycloak"],
      });
      saveLastLoaction();

      authenticated = localStorage.getItem("authenticated");

      if (!JSON.parse(authenticated)) {
        _user.set("sendLoginNotification", true);

        localStorage.setItem("authenticated", true);
      }

      if (!_user.get("email")) {
        _user.set("email", _kcObject?.idTokenParsed?.email);
      }

      _user.save();

      let query = new Parse.Query(Parse.Role);
      query.equalTo("users", _user);
      let roles = await query.find();
      roles = roles.map((role, index) => {
        return role.get("task_name").toString().trim();
      });

      (roles.includes("admin") || roles.includes("manager")) &&
        dispatch({ type: "SHOW_ADMIN_MENU" });
      roles.includes("admin") && dispatch({ type: "IS_ADMIN" });
      await getContract();
      await getTransaction();
      await listOfMyTeam();
      await countTransactionUser();
      await refleshDATASET();
      await listProject();

      setOpen(false);
      listPack();
      const data = await getSporsorId();
      console.log("proposer proposer proposer proposer", data);
      if (!data.error) {
        dispatch({ type: "SAVE_PROPOSER", proposer: data.proposer });
      }

      //throw new Error("warning");
    })();
  }

  useEffect(() => {
    initApp();
  }, []);

  if (user) {
    return (
      <Admin
        dashboard={Dashboard}
        dataProvider={provider.dataProvider}
        history={provider.history}
        customRoutes={customRoutes}
        layout={Layout}
        //catchAll={Dashboard}
      >
        <Resource name="User" />
        <Snack2
          open={need_to_reload}
          message={"Session token expire"}
          muteSnack={() => window.location.reload()}
          type={"error"}
          errorSnack={true}
        />
      </Admin>
    );
  } else {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <_MctIHome_ />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
