import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import img from "../components/img/quest2.png";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import ProjectProgressBar from "./ProjectProgressBar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PaidIcon from "@mui/icons-material/Paid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";


const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};


export default function ProjectCard({
  project,
  onInvest,
  dontShowIcon,
  liked,
  isAdmin,
}) {
  const theme = useTheme();
  const style = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isLove, setIsLove] = React.useState(liked);
  const show_admin_menu = useSelector((state) => state.app.show_admin_menu);
  const is_admin = useSelector((state) => state.app.is_admin);

  const onLove = (project) => {
    setIsLove(!isLove);
    let pro = localStorage.getItem("MCTI_ProjectLike");
    if (!isLove) {
      if (pro) {
        console.log("storage", pro);
        pro += "," + project.objectId;
        localStorage.setItem("MCTI_ProjectLike", pro);
      } else {
        let like = [];
        like.push(project.objectId);
        localStorage.setItem("MCTI_ProjectLike", like.toString());
      }
    } else {
      if (pro) {
        let like = pro.split(",");

        let index = like.indexOf(project.objectId);
        console.log("index", index);
        //  like = like.filter((a) => project.projectId !== a);
        like.splice(index, 1);
        localStorage.setItem("MCTI_ProjectLike", like.toString());
      }
    }
  };

  return (
    <Card sx={{ display: "flex", flexDirection: "row" }}>
      <div style={{ padding: "10px", width: "170px", margin: "auto" }}>
        <CardMedia
          component="img"
          sx={{ width: 150, margin: "auto" }}
          image={project.picture.url}
          alt="Live from space album cover"
        />
      </div>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        className={style.mt}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {project.name}
            {"   "}{" "}
            <Badge
              badgeContent={project.project_id}
              color="primary"
              style={{ marginLeft: "40px" }}
            />
            {new Date(project.start_date?.iso).getTime() >
              new Date().getTime() && (
              <Badge
                badgeContent={"Pending"}
                color="secondary"
                style={{ marginLeft: "60px" }}
              />
            )}
            {!isAdmin && (
              <div style={{ float: "right" }}>
                {!isLove ? (
                  <FavoriteBorderIcon
                    onClick={() => onLove(project)}
                    style={{ marginLeft: "40px" }}
                  />
                ) : (
                  <FavoriteIcon
                    onClick={() => onLove(project)}
                    style={{ marginLeft: "40px" }}
                  />
                )}
              </div>
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.primary"
            component="span"
            sx={{ marginTop: 4, color: "#8C52FF" }}
          >
            {project.investmentType?.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ marginTop: 2 }}
          >
            {project.description.substring(0, 49)}
          </Typography>
          <div className={style.cardContainer}>
            <table>
              <tr>
                <th className={style.tableItemCard}>{project.amount}</th>
                <th className={style.tableItemCard}>{project.return_rate}%</th>
                <th className={style.tableItemCard}>
                  {project.month_investment_maturity} months
                </th>
                <th className={style.tableItemCard}>
                  {(
                    project.month_investment_maturity /
                    project.number_of_payment
                  ).toFixed(2)}{" "}
                  months
                </th>
                <th className={style.tableItemCard}>RIA</th>
              </tr>
              <tbody>
                <tr>
                  <td className={style.tableItemCard}>Amount</td>
                  <td className={style.tableItemCard}>Interest</td>
                  <td className={style.tableItemCard}>Maturity</td>
                  <td className={style.tableItemCard}>Repayments</td>
                  <td className={style.tableItemCard}>Currency</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Box sx={{ marginTop: 4, paddingBottom: 5 }}>
            <ProjectProgressBar
              progress={
                (
                  (new Date().getTime() -
                    new Date(project.createdAt).getTime()) /
                  (new Date(project.start_date?.iso).getTime() -
                    new Date(project.createdAt).getTime())
                ).toFixed(2) *
                  100 >
                100
                  ? 100
                  : (
                      (new Date().getTime() -
                        new Date(project.createdAt).getTime()) /
                      (new Date(project.start_date?.iso).getTime() -
                        new Date(project.createdAt).getTime())
                    ).toFixed(2) * 100
              }
              project={project}
            />
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              Start at{" "}
              {new Date(project.start_date?.iso).toLocaleDateString(
                undefined,
                options
              )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {/* <Button variant="contained">View Project</Button> */}

            {/* <VisibilityIcon /> */}
            {/* <a
              href={project.complete_description.url}
              target="_blank"
              style={{ textDecoration: "none" }}
            > */}

              <Link
                to={"/project/"+project.project_id}
                style={{ textDecoration: "none" }}
              >
              <Button
                variant="outlined"
                sx={{ marginLeft: "10px" }}
                startIcon={<VisibilityIcon />}
              >
                
                See Details
              </Button>
              </Link>

            {dontShowIcon && !project.closed && (
              <Button
                variant="outlined"
                sx={{ marginLeft: "10px" }}
                startIcon={<PaidIcon />}
                onClick={() => onInvest(project.objectId)}
              >
                {new Date(project.start_date?.iso).getTime() >
                new Date().getTime()
                  ? "Start reservation"
                  : "Start investment"}
              </Button>
            )}

            {!dontShowIcon && (
              <Link
                to={"/admin/contracts?id_project=" + project.objectId}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  sx={{ marginLeft: "10px" }}
                  startIcon={<PaidIcon />}
                  //onClick={() => onInvest(project.objectId)}
                >
                  Contract
                </Button>
              </Link>
            )}

            {
              // !dontShowIcon && is_admin  && (
              //   <EditIcon sx={{ marginLeft: "10px", fontSize: "30px" }} />
              // )
            }

            {/* <Button sx={{ marginLeft: "10px" }} variant="contained">
              Update
            </Button> */}
            {!dontShowIcon && show_admin_menu && (
              <Link
                to={"/admin/project/" + project.objectId}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  sx={{ marginLeft: "10px" }}
                  startIcon={<AddBoxIcon />}
                >
                  Details
                </Button>
              </Link>
            )}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  /*  root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: 200,
    },
    closed: {
      width: 55,
    }, */

  tableItemCard: {
    width: 100,
    textAlign: "left",
  },
  cardContainer: {
    border: 1,
    marginTop: 20,
  },
  cardLabel: {
    padding: "2px",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    marginLeft: 30,
  },
  mt: {
    marginTop: 4,
  },
}));
