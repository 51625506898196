import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import Logo from '../../layout/Logo';
import ErrorHandler from '../ErrorHandler'


  
export default function Help(props){
    const a = null;
    function errr(){
        console.log('before throw error');
        throw new Error('I crashed!')
    }
    return(
        <Card style={styles.card}>
            <CardContent>
                <Title title={<Logo title="HELP"/>}/>

                <h2>Clarification notes</h2>
                <p>
                    <i>
                        In order to achieve a successful understanding of the it is critical to understand the meaning of each term.
                    </i>
                </p>
                <p>
                    <span style={styles.title}>
                        Account balance:{" "}
                    </span>
                    This is a total amount of RIA available on an investor account. This is the sum of the value of the following accounts: Principal, Payout and RECO.
                </p>
                <p>
                    <span style={styles.title}>
                    Bonus:{" "}
                    </span>
                    This is the amount paid as the compensation of the work done and the achievement of a given target. For example, based on the achievement of a clearly defined KPI, 
                        the bonus could be paid to a member of BoD, RIA ambassador and so on.
                </p>
                <p>
                    <span style={styles.title}>
                    Cash-out:{" "}
                    </span>
                    The cash withdraw allows the investor to convert RIA from his RECO account into FIAT currency. This is possible when RECO account has a positive balance.
                </p>
                <p>
                    <span style={styles.title}>
                    Commission:{" "}
                    </span>
                    is paid to a RIA community member for contributing to marketing effort, for example when they on-board another other investors.

                </p>
                <p>
                    <span style={styles.title}>
                    Deposit:{" "}
                    </span>
                    This are all transactions that increase the investor account value. Such transactions include Commissions, Bonus, Payouts, Transfer-in and Top-up operations.
                </p>
                <p>
                    <span style={styles.title}>
                    Early withdraw:{" "}
                    </span>
                    RIA investor are given the flexibility to transfer-out or invest more RIA than the amount available in their free balance. Please the early withdraw is not accepted for a cash-out operation. The excess amount is called early withdraw. However, the total amount of early withdraw must not exceed 90% the amount in Payout account. The monthly interest of 5% will be charged at the time of overdraft. If Payout account are going to be affected by a withdraw operation, always post the warning.

                </p>
                <p>
                    <span style={styles.title}>
                    Free balance:{" "}
                    </span>
                    The free balance corresponds to the sum of Principal and RECO accounts. The free balance can be transferred-out or invest without any charge.
                </p>
                <p>
                    <span style={styles.title}>
                    Payment Terms: {" "}
                    </span>
                    The payment terms correspond to the date where the payment was promised to investors. For example, if an investment contract is A1 pack with 6 payment terms, the first term will start at the end of 7th month and will be paid during six months. On the payment date, the corresponding amount of RIA is automatically transferred from the Payout account to RECO account.
                </p>
                <p>
                    <span style={styles.title}>
                    Payout:{" "}
                    </span>

                    The amount received by an investor as the return on investment of an active contract. This is paid every week through the life time of the contract. The value of the weekly payout is obtained by dividing the total yearly payout of the contract by 52. <u>All payouts are recorded in the Payout account</u>. Please note, only automatic transactions are possible on this account: Payout at the end of the week (Deposit) and Payment terms at the due date (withdraw).

                </p>
                <p>
                    <span style={styles.title}>
                    Principal account:{" "}
                    </span>
                    This is the default default recording of RIA transactions. Commissions, Bonus, Top-up and Transfer-in will always be recorded into this account. Likewise, amount of RIA for starting an investment and transfer-out will first be taken from this account.

                </p>
                <p>
                    <span style={styles.title}>
                    RECO:{" "}
                    </span>
                    RECO (Ready For Cash Out) account is where the investor received the RIA at the contractual payment date. The RIA in this account can be converted into traditional or fiat currency then sent to the investors bank account or other means of payment. 
                    <ul>
                        <li>Automatic transfer from the Payout account to RECO is the only mean to deposit RIA in the RECO account.
                        </li>
                        <li>There are three withdraw operations: Investments, Transfer-out and Cash-out. </li>
                    </ul>
                </p>
                <p>
                    <span style={styles.title}>
                    Top-up :{" "}
                    </span>
                    This is a deposit that corresponds to the top-up of their account. For example, the investors buys RIA from MCTI to start investing.
                </p>
                <p>
                    <span style={styles.title}>
                    Transfer in/out:{" "}
                    </span>
                    This transfer-in corresponds to RIA transferred from another investors or another RIA account where transfer-out is the other way around. All transfer-out will be charged a transfer fees of 0.51%.
                </p>
                <p>
                    <span style={styles.title}>
                    Turnover:{" "}
                    </span>
                    The turnover is the cumulative amount of an account deposit or a transaction category. For example investment turnover.
                </p>
                <p>
                    <span style={styles.title}>
                        {" "}
                    </span>
                </p>
                <p>
                    <span style={styles.title}>
                    Withdraw:{" "}
                    </span>
                    All transactions whereby RIA are taken out, so reducing a given account balance. for example a transfer send out or the cash withdraw. At this point there are three types of withdraw transaction which are investment, cash-out and transfer-out. The cash-out is applicable only applicable to RECO account. The investment and transfer-out are based on account balance. 

                </p>
            </CardContent>
        </Card>
    )            
} 


const styles = {
    card: { 
        textAlign: 'left',
    },
    button: {
        textDecoration: 'none'
    },
    customButton: {
        width: "180px"
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold'
    }
}