import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function ProjectProgressBar({ progress, project }) {

  function onPercent(){
    if(project.proceeded){
      if(project.amount_invested){
        return ((project.amount_invested / project.amount) * 100).toFixed(2)
      }else{
        return 100
      }
    }else{
      const bar = (
        (new Date().getTime() -
          new Date(project.createdAt).getTime()) /
        (new Date(project.start_date?.iso).getTime() -
          new Date(project.createdAt).getTime())
      ).toFixed(2) *
        100 >
      100
        ? 100
        : (
            (new Date().getTime() -
              new Date(project.createdAt).getTime()) /
            (new Date(project.start_date?.iso).getTime() -
              new Date(project.createdAt).getTime())
          ).toFixed(2) * 100 
      
      return bar;
    }

  }

  return (
    <Box sx={{ width: "100%", height: "30", mt: 4 }}>
      <LinearProgress variant="determinate" value={onPercent()}  sx={{height:"1.5rem", borderRadius:"7px"}}/>
      <Typography variant="body2" color="text.secondary">
      {onPercent()}%
      </Typography>
    </Box>
  );
}

export default ProjectProgressBar;
