import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DialogProjectPack } from "../../../components/DialogProjectPack";
import { ProjectPackEdit } from "./projectPackEdit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  saveProjectPackage,
  getInvestPlans,
  countShareHolder,
  destroyInvestMentPack,
  submitProjectReport,
  getHolderForProject,
  closeProject,
  findProjectById,
} from "../../../utility/project";
import { Link, useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Alert from "@mui/material/Alert";
import ProjectAdd from "./ProjectAdd";
import ProjectEdit from "./ProjectEdit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Input = styled("input")({
  display: "none",
});

function BasicModal({ id }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSucceed, setIsSucceed] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);

  const [doc, setDoc] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const send = () => {
    const obj = { project_id: id };

    var reader = new FileReader();
    reader.readAsDataURL(doc);

    reader.onload = async () => {
      setLoading(true);
      try {
        const res = await submitProjectReport({
          ...obj,
          project_report: { base64: reader.result },
        });
        console.log("project report", res);
        setIsSucceed(true);
        setLoading(false);
      } catch (error) {
        setIsFailed(true);
        setLoading(false);
      }
    };
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        style={{ marginLeft: 12 }}
      >
        Submit project report
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Project Report
          </Typography> */}
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              flexDirection: "column",
            }}
          >
            {!loading && isSucceed && (
              <Alert severity="success">Report sent successful</Alert>
            )}

            {!loading && isFailed && (
              <Alert severity="error">Report sent failed</Alert>
            )}

            <Typography variant="h6" component="h5">
              Send Project report document
            </Typography>
            <label htmlFor="icon-button-file">
              <Input
                id="icon-button-file"
                onChange={(e) => setDoc(e.target.files[0])}
                type="file"
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <CloudUploadIcon style={{ fontSize: "70px" }} />
              </IconButton>
            </label>
            {doc != null && (
              <Typography variant="h6" component="h5">
                <small> {doc.name}</small>
              </Typography>
            )}
            <div>
              <Button
                type="submit"
                disabled={loading}
                onClick={() => send()}
                sx={{
                  marginLeft: "15px",
                }}
                variant="contained"
              >
                {loading ? "LOADING" : "Submit"}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export function ProjectPack() {
  const [open, setOpen] = React.useState(false);
  const [op, setOp] = React.useState(false);
  const { id } = useParams();
  const [investPlan, setInvestPlan] = React.useState([]);
  const is_admin = useSelector((state) => state.app.is_admin);
  const [shareHolders, setShareHolders] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [numberItem, setNumberItem] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const projectList = useSelector((p) => p.app?.projects);
  const [collapsePlackOpen, onOpenCollapsePack] = React.useState(true);
  const [collapseHolderOpen, onOpenHolderList] = React.useState(false);
  const [project, setProject] = React.useState(
    projectList.find((project) => project.objectId === id)
  );

  const handleClickOpen = () => {
    //setOpen(true);
    setOp(!op);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function calculTabData(page, max) {
    const holders = await getHolderForProject(id);
    setShareHolders(holders);
    console.log("holders", holders);
  }

  const columns = [
    //
    {
      field: "annual_roi",
      headerName: "Annual roi",
      width: 200,
      type: "number",
      sortable: true,
    },
    {
      field: "convertible_stock",
      headerName: "convertible stock",
      width: 200,
      sortable: true,
      type: "boolean",
    },
    {
      field: "cumul_monthly_payout",
      headerName: "cumul monthly payout",
      width: 230,
      type: "number",
    },
    {
      field: "cumulative_stock",
      headerName: "Cumulative stock",
      width: 200,
      type: "boolean",
    },
    {
      field: "delay_first_payout",
      headerName: "delay first payout",
      width: 200,
      type: "number",
    },
    {
      field: "duration",
      headerName: "duration",
      width: 200,
      type: "number",
    },
    {
      field: "formule",
      headerName: "formule",
      width: 200,
    },
    {
      field: "investment_asset",
      headerName: "Investment asset",
      width: 200,
      type: "number",
    },
    {
      field: "monthly_payout",
      headerName: "monthly payout",
      width: 200,
      type: "number",
    },
    {
      field: "one_time_payout",
      headerName: "one time payout",
      width: 200,
      type: "number",
    },
    {
      field: "pack",
      headerName: "pack",
      width: 200,
    },
    {
      field: "participating_stock",
      headerName: "participating stock",
      width: 200,
      type: "boolean",
    },
    {
      field: "preferred_stock",
      headerName: "preferred stock",
      width: 200,
      type: "boolean",
    },
    // {
    //   field: "stock_type",
    //   headerName: "stock type",
    //   width: 200,
    //   type: "boolean",
    // },
    {
      field: "actions",
      type: "actions",
      renderCell: (params) => {
        if (is_admin) return <DeleteIcon onClick={(e) => remove(params, e)} />;
      },
    },

    /*  {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    }, */
  ];

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getProject() {
    setProject(await findProjectById(id));
  }

  React.useEffect(() => {
    //  setProject(projectList.find((project) => project.objectId === id));

    console.log("projet selectionne", project);

    getPlanForProject();
    (async () => {
      // await calculTabData(0, 5);
      const count = await countShareHolder(id);
      console.log(count);
      setNumberItem(count);
      await calculTabData(0, 5);
      await getProject();
    })();
  }, []);

  const remove = (param, e) => {
    e.stopPropagation();
    console.log(param.row);

    const deletePack = async () => {
      const res = await destroyInvestMentPack(param.row.objectId);
      console.log("delete pack", res);
    };

    deletePack();
  };

  const handleCloseProject = () => {
    // closeProject(project)

    const close = async () => {
      const res = await closeProject({ project_id: id });
      getProject();
    };
    close();
  };

  async function getPlanForProject() {
    const results = await getInvestPlans(id);
    setInvestPlan(results);
    // getPlanForProject();
  }

  /* React.useEffect(() => {
    getPlanForProject();
  }, []); */

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div>
        <Link to={"/admin/projects"} underline="none">
          <ArrowBackIcon />
        </Link>
      </div>

      {is_admin && (
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={!op ? <AddIcon /> : <RemoveIcon />}
          >
            {"New Package"}
          </Button>
          {new Date(project.start_date?.iso).getTime() <=
            new Date().getTime() && <BasicModal id={id} />}

          <Button
            onClick={() => handleCloseProject()}
            style={{ marginLeft: 12 }}
            disabled={project.closed}
          >
            Close investment
          </Button>
        </ButtonGroup>
      )}

      {op && <ProjectPackEdit onAdded={getPlanForProject} />}

      <ProjectEdit
        project={projectList.find((project) => project.objectId === id)}
      />

      <div style={{ marginTop: 30 }}>
        <Typography component="span" variant="h4">
          List of Pack investment
        </Typography>

        {collapsePlackOpen ? (
          <KeyboardArrowUpIcon
            fontSize="large"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => onOpenCollapsePack(!collapsePlackOpen)}
          />
        ) : (
          <KeyboardArrowDownIcon
            fontSize="large"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => onOpenCollapsePack(!collapsePlackOpen)}
          />
        )}
      </div>

      {collapsePlackOpen && (
        <DataGrid
          rows={investPlan}
          columns={columns}
          getRowId={(row) => row.objectId}
          style={{ marginTop: "20px" }}
          // pageSize={5}
          autoPageSize
          pagination
          /*   components={{
            LoadingOverlay: LinearProgress,
          }}
          loading */
          //rowsPerPageOptions={[5, 10, 15]}
          //checkboxSelection
        />
      )}

      <Typography component="div" sx={{ marginTop: 4 }} variant="h4">
        List of Shareholders
      </Typography>

      {collapseHolderOpen ? (
        <KeyboardArrowUpIcon
          fontSize="large"
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => onOpenHolderList(!collapseHolderOpen)}
        />
      ) : (
        <KeyboardArrowDownIcon
          fontSize="large"
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => onOpenHolderList(!collapseHolderOpen)}
        />
      )}

      {collapseHolderOpen && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">FirstName</TableCell>
                <TableCell align="left">LastName</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone Number</TableCell>
                <TableCell align="left">amount invested</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shareHolders.map((row) => (
                <TableRow>
                  <TableCell align="left">{row.userMain.first_name}</TableCell>
                  <TableCell align="left">{row.userMain.last_name}</TableCell>
                  <TableCell align="left">{row.userMain.email}</TableCell>
                  <TableCell align="left">
                    {row.userMain.phone_number}
                  </TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={[5, 10, 25]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        </TableContainer>
      )}
      {/*  <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">FirstName</TableCell>
              <TableCell align="left">LastName</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left">amount invested</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shareHolders.map((row) => (
              <TableRow>
                <TableCell align="left">{row.userMain.first_name}</TableCell>
                <TableCell align="left">{row.userMain.last_name}</TableCell>
                <TableCell align="left">{row.userMain.email}</TableCell>
                <TableCell align="left">{row.userMain.phone_number}</TableCell>
                <TableCell align="left">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
        />
      </TableContainer> */}

      {/* <DialogProjectPack open={open} handleClose={handleClose} /> */}
    </div>
    //
  );
}
