import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@material-ui/core/styles';

const Logo = ({title}) => {
    const theme = useTheme();
    return (
        <span style={{display: 'flex', alignItems: 'center'}}>
            <div style={{paddingTop: "5px"}} > 
                <img src="https://www.mctibv.com/img/logo.png" alt="" width="40" height="40" />
            </div>
            <span style={{paddingLeft: '10px'}}>{title}</span>
        </span>
    );
};

export default Logo;
