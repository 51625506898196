import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import UpdateIcon from '@mui/icons-material/Update';

const columns = [
  {
    id: 'name',
    label: 'User',
    align: 'right',
  },
  {
    id: 'amount',
    label: 'Value',
    align: 'right',
  },
  {
    id: 'date',
    label: 'Date',
    align: 'right',
  },
  {
    id: 'reason',
    label: 'Reason',
    align: 'right',
  },
];

function createData(user, value, date) {
  return { user, value, date };
}



export default function HistoricRIaUpdate({rows, onRolback}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"left"}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                align={"left"}
              >
                {""}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={"left"}>
                          {value}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      align={"left"}
                    >
                      <UpdateIcon onClick={()=>onRolback(row)} style={{marginTop: "-5px", marginLeft: "10px"}} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
