import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {colors, stylesMain} from '../themes'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {inviteFriend, WAIT} from '../utility'
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

export default function InviteFriend(props) {

  const [emails, setEmails] = React.useState([]);
  const [email, setEmail] = React.useState([]);
  const [visibleForm, setVisible] = React.useState(true);
  const [showAlert, setShow] = React.useState(false);
  const [loading, setLoad] = React.useState(false);
  const [message, setMess] = React.useState({});

  const handleClickOpen = () => {
    props.onChangeState(true)
  };

  const handleClose = () => {
    props.onChangeState(false)
    setVisible(true)
    setShow(false)
  };
  const handleInvite = async () => { 
    setShow(false)
    if(emails.length == 0){
      setMess({error: true, message: 'You must provide at least one  email address'})
      setShow(true)
      return 
    }
    setLoad(true)
    const res = await inviteFriend(emails)
    //warning
    setMess(res)
    setShow(true)
    setLoad(false)
    setEmails([])
    WAIT({function: handleClose});
  };

  const add = (e)  => {
      let sliceM = []
      emails.forEach(element => {
        sliceM.push({...element, last: false})
      });

      sliceM.push({email: e, last: true})
      setEmails(sliceM)
      setVisible(false)
      setEmail('')
  }
  
  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Invite fiend</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {!showAlert ? "Please collect an emails to send referal link" :
                <Alert severity={!message.error ? (message.warning ? "warning" :"success") : "error"}>{message.message}</Alert>}
            </DialogContentText> 
            <Typography sx={{ ml: 2, flex: 1 }} component="div" style={{color: colors.primary}}>
                {emails.map((e, k) =>{

                    return (
                        <div style={{display: 'flex'}} key={k}>
                            {e.email}
                                {e.last && !visibleForm && emails.length <10  &&
                                    <AddCircleIcon 
                                        onClick={()=>setVisible(true)}
                                        style={{color: colors.primary, height: '30px', width: '100px'}}
                                    />
                                }
                        </div>
                    )
                })}
            </Typography>
            {visibleForm &&
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                    <div style={{display: 'flex'}}>
                        {/* <input 
                          type="text" 
                          name="email"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        /> */}
                        <TextField  
                            label="Email" 
                            value={email} 
                            onChange={(e)=>setEmail(e.target.value)} 
                            variant="outlined" 
                            fullWidth
                        />
                        <CheckCircleIcon 
                            variant="contained" 
                            onClick={()=>add(email)} 
                            style={{color: colors.primary, height: '30px', width: '50px', marginTop: '15px'}}
                        // onClick={()=>setOpenInviteFriend(!openInviteFriend)}
                    />
                    </div>
                </Typography>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInvite} disabled={loading}>
            {loading && <CircularProgress size={20} />}
            Send email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}