import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
//import { getInvestmentType } from "../../../utility";
import { saveProjectPackage, getInvestPlans } from "../../../utility/project";
import { packagesLocale } from "../../../components/Packages";
import { useForm } from "react-hook-form";

/* import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker"; */

const Input = styled("input")({
  display: "none",
});

export function ProjectPackEdit({ onAdded }) {
  const [formule, setFormule] = React.useState("");
  const [stock_type, setType] = React.useState("");
  const [pack, setPack] = React.useState("");
  const [duration, setDuration] = React.useState(0);
  const [delay_first_payout, setDelay] = React.useState(0);
  const [investment_asset, setIvest] = React.useState(0);
  const [annual_roi, setAnnualRoi] = React.useState(0);
  const [monthly_payout, setMontly] = React.useState(0);
  const [cumul_monthly_payout, setCumul] = React.useState(0);
  const [one_time_payout, setOneTime] = React.useState(0);

  const [preferred_stock, setPreferredStock] = React.useState(false);
  const [cumulative_stock, setCumulativeStock] = React.useState(false);
  const [participating_stock, setParticipatingStock] = React.useState(false);
  const [convertible_stock, setConvertibleStock] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { id } = useParams();

  const onSubmit = (data) => {
    console.log("data", data);
    let investmentPlan = {
      project_id: id,
      formule: data.formule,
      stock_type: stock_type,
      pack: data.pack,
      duration: parseInt(data.duration),
      delay_first_payout: parseInt(data.delay_first_payout),
      investment_asset: parseInt(data.investment_asset),
      annual_roi: parseFloat(data.annual_roi),
      monthly_payout: parseFloat(data.monthly_payout),
      one_time_payout: parseFloat(data.one_time_payout),
      cumulative_stock: data.cumulative_stock,
      cumul_monthly_payout: parseFloat(data.cumul_monthly_payout),
      preferred_stock: data.preferred_stock,
      participating_stock: data.participating_stock,
      convertible_stock: data.convertible_stock,
    };

    //   async saveProject()
    const save = async () => {
      const res = await saveProjectPackage(investmentPlan);
      console.log(res);
      onAdded();
    };

    save();
  };

  async function getPlanForProject() {
    console.log("before get pack");
    const results = await getInvestPlans();
    console.log(results);
  }

  React.useEffect(() => {
    getPlanForProject();
  }, []);

  return (
    <Paper elevation={5}>
      <Stack
        gap={5}
        spacing={2}
        alignItems="center"
        style={{
          paddingBottom: "30px",
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        <Box>
          <Box>
            <Typography component="div" variant="h4" sx={{ marginTop: 4 }}>
              New Pack Project
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "50ch" },
            }}
            xs={12}
            md={8}
            sm={8}
            lg={8}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            // onSubmit={onSubmit}
            autoComplete="on"
          >
            <div>
              <TextField
                select
                label="Formule"
                required
                error={errors.formule}
                // error={formule.toString() == ""}

                {...register("formule", { required: true })}
                // onChange={(e) => setFormule(e.target.value)}
                helperText={errors.formule ? "Field required" : ""}
              >
                <MenuItem value={"A"}>A</MenuItem>
                <MenuItem value={"B"}>B</MenuItem>
                <MenuItem value={"C"}>C</MenuItem>
              </TextField>
            </div>

            <div>
              <TextField
                select
                required
                label="Name of package"
                {...register("pack", { required: true })}
                error={errors.pack}
                helperText={errors.pack ? "Field required" : ""}

                //   onChange={(e) => setPack(e.target.value)}
              >
                {packagesLocale.map((pack) => (
                  <MenuItem value={pack.name.replace(" - ", " ")}>
                    {pack.name}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"A1 Sarter"}>A1 Sarter</MenuItem>
            <MenuItem value={"a2_standard"}>a2_standard</MenuItem>
            <MenuItem value={"a3_premium"}>a3_premium</MenuItem>
            <MenuItem value={"b1_starter"}>b1_starter</MenuItem>
            <MenuItem value={"b2_standard"}>b2_standard</MenuItem>
            <MenuItem value={"b3_premium"}>b3_premium</MenuItem>
            <MenuItem value={"c1_starter"}>c1_starter</MenuItem>
            <MenuItem value={"c2_standard"}>c2_standard</MenuItem>
            <MenuItem value={"c3_premium"}>c3_premium</MenuItem> */}
              </TextField>

              <TextField
                id="outlined-required"
                label="Duration"
                required
                {...register("duration", { required: true })}
                error={errors.duration}
                helperText={errors.duration ? "Field required" : ""}
                type="number"
                // onChange={(e) => setDuration(e.target.value)}
              />
            </div>
            <div>
              <TextField
                id="outlined-disabled"
                required
                {...register("delay", { required: true })}
                error={errors.delay}
                helperText={errors.delay ? "Field required" : ""}
                type={"number"}
                //  onChange={(e) => setDelay(e.target.value)}
                label="Delay first payout"
              />

              <TextField
                id="outlined-required"
                label="Investment asset"
                {...register("investment_asset", { required: true })}
                error={errors.investment_asset}
                helperText={errors.investment_asset ? "Field required" : ""}
                required
                type="number"
                //  onChange={(e) => setIvest(e.target.value)}
              />
            </div>

            <div>
              <TextField
                id="outlined-disabled"
                required
                type={"number"}
                {...register("annual_roi", { required: true })}
                error={errors.annual_roi}
                helperText={errors.annual_roi ? "Field required" : ""}
                // onChange={(e) => setAnnualRoi(e.target.value)}
                label="Annual roi"
              />

              <TextField
                id="outlined-required"
                label="Monthly payout"
                required
                {...register("monthly_payout", { required: true })}
                error={errors.monthly_payout}
                helperText={errors.monthly_payout ? "Field required" : ""}
                type="number"
                // onChange={(e) => setMontly(e.target.value)}
              />
            </div>

            <div>
              <TextField
                id="outlined-disabled"
                required
                type={"number"}
                {...register("cumul_monthly_payout", { required: true })}
                error={errors.cumul_monthly_payout}
                helperText={errors.cumul_monthly_payout ? "Field required" : ""}
                // onChange={(e) => setCumul(e.target.value)}
                label="cumul monthly payout"
              />

              <TextField
                id="outlined-required"
                label="One Time Payout"
                {...register("one_time_payout", { required: true })}
                error={errors.one_time_payout}
                helperText={errors.one_time_payout ? "Field required" : ""}
                required
                type="number"
                //onChange={(e) => setOneTime(e.target.value)}
              />
            </div>

            <div style={{ marginBottom: 18 }}>
              <span>
                <input type="checkbox" {...register("prefered_stock")} />
                <label>Prefered stock</label>
              </span>

              <span>
                <input type="checkbox" {...register("cumulative_stock")} />
                <label>Cumulative stock</label>
              </span>
              <span>
                <input type="checkbox" {...register("participating_stock")} />
                <label>Participating stock</label>
              </span>
              <span>
                <input type="checkbox" {...register("convertible_stock")} />
                <label>Convertible stock</label>
              </span>
              {/* <FormControlLabel
                control={
                  <Checkbox
                   
                    {...register("prefered_stock")}
                    name="Prefered_stock"
                  />
                }
                label="Prefered stock"
              /> */}
              {/* <FormControlLabel
                {...register("cumulative_stock")}
                name="Cumulative_stock"
                control={
                  <Checkbox
                  
                  />
                }
                label="Cumulative stock"
              />
              <FormControlLabel
                control={
                  <Checkbox
                  
                    {...register("participating_stock")}
                    name="Participating_stock"
                  />
                }
                label="Participating stock"
              />
              <FormControlLabel
                control={
                  <Checkbox
                   
                    {...register("convertible_stock")}
                    name="Convertible_stock"
                  />
                }
                label="Convertible stock"
              /> */}
            </div>

            <Button
              // onClick={() => onSubmit()}
              type="submit"
              style={{ float: "left" }}
              variant="contained"
            >
              Add Pack Project
            </Button>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}
