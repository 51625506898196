import * as React from "react";



export default class ErrorHandler extends React.Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.log({ error, errorInfo });
        this.setState({ errorInfo });
    }
  
    render() {
        const { hasError, errorInfo } = this.state;
        if (hasError) {
            return (
                <div className="card my-5">
                <div className="card-header">
                    <p>
                    There was an error in loading this page.{' '}
                    <span
                        style={{ cursor: 'pointer', color: '#0077FF' }}
                        onClick={() => {
                        window.location.reload();
                        }}
                    >
                        Reload this page
                    </span>{' '}
                    </p>
                </div>
                <div className="card-body">
                    <project className="error-project">
                    <summary>Click for error project</summary>
                    {errorInfo && errorInfo.componentStack.toString()}
                    </project>
                </div>
                </div>
            );
        }else{
            return this.props.children;
        }

    }
  }