import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {getContractAdmin, countContract, idGenerateFileName, saveParseFile, saveContractFile, getContract} from '../../utility'
import { Title } from 'react-admin';
import Button from '@material-ui/core/Button';
import {colors} from '../../themes'
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import {exts} from "./extension"
import { Responsive } from "responsive-react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery } from 'react-responsive'

function createData(name, user, contract_id, weekly, volume, annuelRoi, investment_asset, contract_certificate) {
  return {
    name,
    user,
    contract_id,
    weekly,
    volume,
    annuelRoi,
    investment_asset,
    contract_certificate
  };
}

let rows = []
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'user',
    numeric: false,
    disablePadding: true,
    label: 'User',
  },
  {
    id: 'contract_id',
    numeric: false,
    disablePadding: true,
    label: 'Contract ID',
  },
  {
    id: 'weekly',
    numeric: false,
    disablePadding: false,
    label: 'Weekly ROI',
  },
  {
    id: 'volume',
    numeric: false,
    disablePadding: false,
    label: 'Volume',
  },
  {
    id: 'annuelRoi',
    numeric: false,
    disablePadding: false,
    label: 'Annual ROI',
  },
  {
    id: 'investment_asset',
    numeric: true,
    disablePadding: false,
    label: 'Investment asset',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* 
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
        align={'left'}
        padding={'normal'}
        >
            <TableSortLabel
                //active={orderBy === headCell.id}
                //direction={orderBy === headCell.id ? order : 'asc'}
                >
                {"Actions"}
            </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transaction
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export function Contract() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [numberItem, setNumberItem] = React.useState(0);
  const [file_name, setName] = React.useState("");




  const dispatch = useDispatch()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const [tabData, setTabData] = React.useState([])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tabData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => { 
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberItem) : 0;

    async function calculTabData(page, max){
        let locRow = [] 

        let contracts = await getContractAdmin(page, max, getParameterByName("id_project"));
        console.log('contracts contracts contracts contracts .............', contracts)
        if(contracts){
            console.log('contracts contracts contractsv', contracts)
            for(let i = 0; i < contracts.length; i++){
                const tem =  contracts[i]
                // const date =  String(new Date(tem.createdAt).toString().split('GMT')[0])
                // const task_type = tem.transactionType?.task_name
                
                const contract_certificate = tem?.contract_certificate?.url
                const user = tem.user? (tem.user.last_name? tem.user.last_name: "") + " "+(tem.user.first_name?tem.user.first_name: "") : ""
                //const annuelRoi = tem.annuelRoi? tem.annuelRoi.last_name + " "+tem.annuelRoi.first_name : ""
                
                locRow.push(
                    createData(
                        tem.objectId, 
                        user,
                        tem.contract_id, 
                        tem.weekly_roi, 
                        tem.volume,
                        tem.annual_roi,  
                        tem.investment_asset, 
                        contract_certificate
                    )
                )
            }
            console.log('locRow locRow locRow', locRow)
            setTabData(locRow)
        }
    }
    React.useEffect(()=>{ 
        (async ()=>{
            const count = await countContract(getParameterByName("id_project"))
            setNumberItem(count)
            console.log('count count count count', count)
            await calculTabData(0, 5)
        })()
    }, [])

    const handleChange = async (e, idContract) => {
      const file = e.target.files[0];
      console.log('e**************************', file.name)
      if(exts.includes((file.name).split('.')[1])){
        setName(file.name)
        dispatch({ type: "RELOAD", state: true})
        const normalizeName = await  idGenerateFileName("_CONTRACT_", file.name)
        console.log('parseFile parseFile parseFile before', normalizeName)
        const parseFile = await saveParseFile(normalizeName, file)
        console.log('savec key key key', parseFile)
        const res = await saveContractFile(idContract, parseFile)
        if(!res){
          window.alert('Error uploading contract !')
        }
        calculTabData(page, rowsPerPage)
        dispatch({ type: "RELOAD", state: false})
        const contacts = await getContract()
        dispatch({ contacts: contacts, type: "SAVE_CONTRACTS" })
      }else{
        window.alert('File format is not supported !')
      }
    }

    const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <Box sx={{ width: '100%' }}>
      <Title title="TRANSACTION" />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Responsive displayIn={["Laptop", "Tablet"]}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberItem}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(tabData, getComparator(order, orderBy))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding={'normal'}
                        >
                          {row.user}
                        </TableCell>
                        <TableCell align="left">{row.contract_id}</TableCell>
                        <TableCell align="left">{parseFloat(row.weekly).toFixed(2)}</TableCell>
                        <TableCell align="left">{row.volume}</TableCell>
                        <TableCell align="left">{row.annuelRoi}</TableCell>
                        <TableCell align="left">{row.investment_asset}</TableCell>
                        <TableCell align="left">
                          <Typography component="div" variant="h5" >
                            <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
                              <label for={index} >
                                  <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: 'pointer'}} />
                              </label>
                              <input 
                                id={index} 
                                //disabled={!!row.contract_certificate}
                                type="file" 
                                onChange={(e) =>handleChange(e, row.name)} 
                                style={{display: 'none'}}
                              /> 
                              {!!row.contract_certificate&&
                                <a href={row.contract_certificate} style={{fontSize: "12px"}} target="_blank">Contract</a>
                              }
                            </FormControl>  
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow> 
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Responsive>





        {
          //Mobile view started here ********
        }



        <Responsive displayIn={["Mobile"]}>
          {stableSort(tabData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {

              return (
                <CardResponsiveContract
                  row={row}
                  key={index}
                  cle={index}
                  handleChange={handleChange}
                />
              );
            })}
          
        </Responsive>



        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberItem}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={isMobile? "Rows:" : "Rows per page:"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function CardResponsiveContract({row, cle, handleChange}){
  return(
    <>
      <br/>
      <Card sx={{ minWidth: 275 }} style={{borderColor: 'red'}}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color={colors.primary} gutterBottom>
            {row.contract_id}
          </Typography>
          <Typography variant="h5" component="div">
            <Typography variant="body2" component="i">
              Volume:{" "}
            </Typography>
            {row.volume}
          </Typography>
          <Typography variant="body2">
            Weekly Roi:{" "}{parseFloat(row.weekly).toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Annual Roi:{" "+row.annuelRoi}
          </Typography>

          <Typography variant="body2">
          <i> Investment asset:</i>{" "+row.investment_asset}
          </Typography>
          <Typography variant="body2">
          <i> User:</i>{" "+row.user}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography component="div" variant="h5" >
            <FormControl sx={{ m: 1, width: '100%', height: '50px' }} variant="outlined" >
              <label for={cle} >
                  <CloudDownloadOutlinedIcon  style={{color: "#8C52FF" , transform: 'translateY(5px)', cursor: 'pointer'}} />
              </label>
              <input 
                id={cle} 
                //disabled={!!row.contract_certificate}
                type="file" 
                onChange={(e) =>handleChange(e, row.name)} 
                style={{display: 'none'}}
              /> 
              {!!row.contract_certificate&&
                <a href={row.contract_certificate} style={{fontSize: "12px"}} target="_blank">Contract</a>
              }
            </FormControl>  
          </Typography>
        </CardActions>
      </Card>
    </>
  )
}
