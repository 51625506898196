import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Title } from "react-admin";

import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
//import { exts } from "./extension";
import { Responsive } from "responsive-react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useMediaQuery } from "react-responsive";
import { listProject } from "../../../utility/project";

import { exts } from "../extension";
import ProjectCard from "../../../components/ProjectCard";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import ProjectAdd from "./ProjectAdd";

let rows = [];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Project() {
  const [page, setPage] = React.useState(0);
  const projects = useSelector((p) => p.app.projects);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [numberItem, setNumberItem] = React.useState(projects.length);
  const is_admin = useSelector((state) => state.app.is_admin);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    calculTabData(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    calculTabData(0, parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function calculTabData(page, max) {
    const projects = await listProject();

    console.log("projects projects", projects);
  }

  React.useEffect(() => {
    (async () => {
      // await calculTabData(0, 5);
    })();
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Title title="PROJECT" /> */}
      {is_admin && <ProjectAdd />}

      <Box sx={{ width: "100%", mb: 2, marginTop: "10px" }}>
        <Grid container spacing={2}>
          {projects.map((p, i) => (
            <Grid item xs={12} md={12} sm={6} lg={6} key={i}>
              <ProjectCard project={p} isAdmin={true} onInvest={null} />
            </Grid>
          ))}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={numberItem}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={isMobile ? "Rows:" : "Rows per page:"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
