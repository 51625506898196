import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {colors, stylesMain} from '../themes'
import Alert from '@mui/material/Alert';
import {getTransferRiaInfos, WAIT, transferRIA, verificationOTP} from '../utility'
import CircularProgress from '@mui/material/CircularProgress';
import { WEBSITE_URL } from '../config';
import { useSelector } from "react-redux";

const labelCheck = { inputProps: { 'aria-label': 'Switch demo' } };

export default function DialogTransfert(props){ 

  const [acceptCondition, setAccept] = React.useState(false)
  const [account_id, setACcount] = React.useState("")
  const [riaAmount, setAmountRia] = React.useState(0)
  const [amounCheck, setAmountCheck] = React.useState(0)
  const [loading, setLoanding] = React.useState(false)
  const [loading2, setLoanding2] = React.useState(false)
  const [flashType, setFlasType] = React.useState("success")
  const [message, setFlasMessage] = React.useState("")
  const [showFlass, setShowFlash] = React.useState(false)
  const [transInfos, showInfosTrasaction] = React.useState(false)
  const [firstResult, setFirst] = React.useState({})
  const [otp, setOTP] = React.useState(null)
  const [showlik, setShowLink] = React.useState(false)
  const [otpSend, setOTPSend] = React.useState(false)
  const [transaction, setTrans] = React.useState({})
  const [errorReason, setError] = React.useState(false)

  const [reason, setReason] = React.useState("")

  const proposer = useSelector(p => p.app.proposer)


  const transfertRIA = async () => {
      setLoanding(true)
      setError(false)
      if(!acceptCondition){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("You must acept terms and conditions !")
        return 
      }
      if(!riaAmount || riaAmount < 0){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("The RIA amount is invalid. Please submit a valid value.")
        return 
      }
      if(!account_id || account_id&&account_id.trim() === ""){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setFlasMessage("You must provide receiver account ID !")
        return 
      }
      if(!reason || reason.length > 50){
        setFlasType("error")
        setLoanding(false)
        setShowFlash(true)
        setError(true)
        setFlasMessage("The reason must be defined and less than 50 characters.")
        return 
      }
      const o = {ria_amount: parseFloat(riaAmount), account_id: account_id}
      const res = await getTransferRiaInfos(o) 
      console.log('res res res res', res, res?.user?.kycValidated)
      if(res && res.error){
        setFlasType("error")
        setFlasMessage(res.message)
        if(res && res.account_not_verified){
          setShowLink(true)
        }
      }
      else if(!res?.user?.kycValidated){
        setFlasType("error")
        setShowLink(false)
        setFlasMessage("Not Allowed: Receiver’s account not verified")
      }
      else{
        setFlasType("success")
        showInfosTrasaction(true)
        setFirst(res)
        //WAIT({function: closeAndReload});
        setFlasMessage("Please confirm transaction !")
      }
      setAmountCheck(parseFloat(riaAmount))
      setLoanding(false)
      setShowFlash(true)
      //setAmountRia(0)
      
  };
  const handleCheckbox = async (event, isChecked) => {
    setAccept(isChecked)
    console.log('event, isChecked, value', event, isChecked)

  }
  const transferRIA2 = async () => {
      setLoanding2(true)
      setShowLink(false)
      setError(false)
      if(!amounCheck || amounCheck < 0){
        setFlasType("error")
        setLoanding2(false)
        setShowFlash(true)
        setFlasMessage("The RIA amount is invalid. Please submit a valid value.")
        return 
      }
      const o = {ria_amount: parseFloat(amounCheck), account_id: account_id, otp_code: otp, reason: reason}
      const res = await transferRIA(o)
      console.log('res res res res', res)
      if(res && res.error){
        setFlasType("error")
        if(res && res.account_not_verified){
          setShowLink(true)
        }
      }else{
        setFlasType("success")
        //showInfosTrasaction(true)
        setTrans(res.transaction)
        setOTPSend(true)
      }
      console.log('res.transaction', res.transaction)
      setFlasMessage(res&&res.message || "Undefined message")
      setLoanding2(false)
      setShowFlash(true)
      setAccept(false)
      setReason('')
      
  };

  const onConfirm = async ()=>{
    setLoanding2(true)
    setError(false)
    if(!otp||otp&&otp.trim() === ""){
      setFlasType("error")
      setLoanding2(false)
      setShowFlash(true)
      setFlasMessage("OTP is required !")
      return 
    }
    const res = await verificationOTP({new_recharge_amount: parseFloat(amounCheck), objectId: transaction.objectId, otpCode: otp})
    console.log('result result result', res, otp)
    if(res && !res.error){
      setFlasType("success")
      WAIT({function: closeAndReload});
    }else{
      setFlasType("error")
    }
    setFlasMessage(res&&res.message || "Undefined message")
    setLoanding2(false)
    setShowFlash(true)
    setReason('')
}
  const closeAndReload = () => {
    console.log('after time out');
    setFirst({})
    setOTP(null)
    setError(false)
    setAccept(false)
    showInfosTrasaction(false)
    handleClose(true)
    setOTPSend(false)
    setReason('')
   }

  const handleClose = (reload) => {
    setFirst({})
    setOTP(null)
    setError(false)
    setAccept(false)
    showInfosTrasaction(false)
    setShowFlash(false)
    setLoanding(false)
    setACcount("")
    setReason('')
    setAmountRia(0)
    setOTPSend(false)
    props.onChangeState(false, reload)
  };
  const handleChange = (e) => {
    setAmountRia(e.target.value)
  };
  const handleChangeOTP = (e) => {
    setOTP(e.target.value)
  };
  const handleChangeAcc = (e) => {
    setACcount(e.target.value)
  };

  return (
    <div>
      <Dialog open={props.open} fullWidth={false} maxWidth={'md'}>
        <DialogTitle>Transfert</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {showFlass&&
          < Alert severity={flashType}>
              {message} 
              {showlik&& 
                <a href={WEBSITE_URL+"/update-infos"+(proposer&&proposer.userSecure ? "/"+proposer.userSecure.account_id : "")} target="_blank">
                  {" Verify"}
                </a> 
              }
            </Alert>
          }
          </DialogContentText>
            <>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Amount of RIA"
                type="number"
                fullWidth
                disabled={transInfos}
                onChange={handleChange}
                value={riaAmount}
                variant="outlined"
                />
              <TextField
                autoFocus
                margin="dense"
                id="name"
                disabled={transInfos}
                
                label="User account ID"
                type="text"
                fullWidth
                onChange={handleChangeAcc}
                value={account_id}
                variant="outlined"
                /> 

              <TextField  
                  label="Transfer reason" 
                  disabled={transInfos} 
                  multiline
                  autoFocus
                  error={errorReason}
                  fullWidth
                  helperText={"Max length 50 characters"}
                  value={reason} 
                  style={{marginTop: "10px"}}
                  aria-label="minimum height"
                  minRows={2}
                  onChange={(e)=>setReason(e.target.value)} variant="outlined" 
              />

                {!transInfos && 
                  <Typography component="div" variant="h5" style={{fontSize: stylesMain.fontSize}}>                            
                      <Checkbox onChange={handleCheckbox} {...labelCheck} style={{color: colors.primary}} checked={acceptCondition} />
                      <a href={WEBSITE_URL+"/privacy-policy"} target="_blank">
                        Accept the Terms & Conditions
                      </a>
                  </Typography>
                }
            </>
            {transInfos &&
              <>
                <h3>Tranfert infos</h3>
                <p>
                  <span>Account ID: {account_id}</span>  <br/>
                  <span>Name: {firstResult?.user?.first_name +' '+ firstResult?.user?.last_name}</span>  <br/>
                  <span>Amount to pay: {parseFloat(firstResult.amount_paid).toFixed(2)}</span>
                </p>
                  {otpSend && <>
                      <h3>Confirm with your otp code</h3>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Please enter your OTP code"
                        type="text"
                        fullWidth
                        onChange={handleChangeOTP}
                        value={otp}
                        variant="outlined"
                      />
                    </>
                }
              </>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {!transInfos &&
            <Button onClick={transfertRIA} disabled={loading}>
            {loading && <CircularProgress size={20} />}
              Process
            </Button>
          }
            {transInfos && !otpSend &&
              <Button onClick={transferRIA2} disabled={loading}>
              {loading2 && <CircularProgress size={20} />}
                Transfer
              </Button>
            }
            {otpSend &&
              <Button onClick={onConfirm} disabled={loading}>
              {loading2 && <CircularProgress size={20} />}
                Confirm
              </Button>
            }
        </DialogActions>
      </Dialog>
    </div>
  );
}