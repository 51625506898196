import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snack2({message, open, muteSnack, type}) {
  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    muteSnack(false);
  };

  
  return (
    <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    // TransitionProps={{ onExited: handleExited }}
    message={message}
    action={
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          Refresh Token
        </Button>
      </React.Fragment>
    }
  />
  );
}


