import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PhotoCamera } from "@mui/icons-material";
import { getInvestmentType, saveProject } from "../../../utility";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { listProject } from "../../../utility/project";
import { useForm } from "react-hook-form";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";

export default function ProjectAdd({ project }) {
  const [amount, setAmount] = React.useState(0);
  const [number_of_payment, setNumberOfPayment] = React.useState(1);
  const [name, setName] = React.useState("");
  const [start_date, setStartDate] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [month_investment_maturity, setMonthInvest] = React.useState(0);
  const [return_rate, setRate] = React.useState(0);
  const [investmentType, setType] = React.useState("");
  const [picture, setLogo] = React.useState(null);
  const [investmentTypes, setInvestmentType] = React.useState([]);
  const [complete_description, setDocumentDescription] = React.useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  //const [projectForm, onSHowProjectForm] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [projectForm, onSHowProjectForm] = React.useState(false);

  /* const onSubmit = () => {
    console.log(
      name,
      description, 
      picture,
      month_investment_maturity,
      investmentType,
      start_date,
      return_rate,
      investmentType, 
      complete_description
    );

      if(picture){
  
        var reader = new FileReader();
        reader.readAsDataURL(picture);

        reader.onload =  async  () => {
          var reader2 = new FileReader();
          reader2.readAsDataURL(complete_description);
          
          reader2.onload =  async  () => {
            const res = await saveProject({
                name,
                description, 
                picture: {base64: reader.result},
                month_investment_maturity,
                investmentType,
                start_date,
                amount,
                return_rate,
                number_of_payment: parseInt(number_of_payment),
                complete_description : {base64: reader2.result}

            })

            console.log('saving project result', res)

            onSHowProjectForm(false)
            listProject()
          }

          reader2.onerror = function (error) {
            console.log('Error reader 2: ', error);
          };

        };

        reader.onerror = function (error) {
          console.log('Error: ', error);
        };

      }else{
        window.alert('Project picture must be provided')
      }

  }; */
  const onSubmit = (data) => {
    console.log(data);
    var reader = new FileReader();
    reader.readAsDataURL(data.picture[0]);

    reader.onload = async () => {
      var reader2 = new FileReader();
      reader2.readAsDataURL(data.complete_description[0]);

      reader2.onload = async () => {
        /*   const res = await saveProject({
            name: data.name,
            description: data.description,
            picture: { base64: reader.result },
            month_investment_maturity: data.month_investment_maturity,
            investmentType: data.investmentType,
            start_date: data.start_date,
            monthly_repayment: data.monthly_repayment,
            amount: data.amount,
            return_rate: data.return_rate,
            number_of_payment: parseInt(number_of_payment),
            complete_description: { base64: reader2.result },
          }); */

        console.log({
          name: data.name,
          description: data.description,
          picture: { base64: reader.result },
          objectId: project.objectId,
          month_investment_maturity: data.month_investment_maturity,
          investmentType: data.investmentType,
          start_date: data.start_date,
          monthly_repayment: data.monthly_repayment,
          amount: data.amount,
          return_rate: data.return_rate,
          number_of_payment: parseInt(number_of_payment),
          complete_description: { base64: reader2.result },
        });

        //  onSHowProjectForm(false);
        listProject();
      };

      reader2.onerror = function (error) {
        console.log("Error reader 2: ", error);
      };
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  async function getType() {
    const results = await getInvestmentType();

    console.log(results);
    setInvestmentType(results);
  }

  React.useEffect(() => {
    getType();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!projectForm && (
          <Button
            variant="contained"
            onClick={() => onSHowProjectForm(!projectForm)}
          >
            Edit Project
          </Button>
        )}
      </Box>
      {projectForm && (
        <Paper elevation={5}>
          <Stack
            gap={5}
            spacing={2}
            alignItems="center"
            style={{ paddingBottom: "30px" }}
          >
            <Box>
              <Typography component="div" variant="h4" sx={{ marginTop: 4 }}>
                Edit Project
              </Typography>
            </Box>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "50ch" },
              }}
              xs={12}
              md={8}
              sm={8}
              lg={8}
              noValidate
              // onSubmit={onSubmit}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={project.picture.url}
                  alt={project.picture.name}
                  style={{ width: 200, height: 200 }}
                />
              </div>
              <div>
                <TextField
                  type="file"
                  // required
                  label="Project Logo"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("picture")}
                  // error={errors.picture}
                  // helperText={errors.picture ? "Field required" : ""}
                  // onChange={(e) => setLogo(e.target.files[0])}
                />

                {/* <TextField
                  id="outlined-number"
                  label="Number of payment"
                  required
                  {...register("number_of_payment", { required: true })}
                  type="number"
                  error={errors.number_of_payment}
                  helperText={errors.number_of_payment ? "Field required" : ""}
                  
                /> */}
                <TextField
                  id="outlined-number"
                  label="Monthly payment"
                  required
                  {...register("monthly_repayment", { required: true })}
                  type="number"
                  error={errors.monthly_repayment}
                  helperText={errors.monthly_repayment ? "Field required" : ""}
                />
              </div>
              <div>
                <TextField
                  id="outlined-disabled"
                  required
                  value={project.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.name}
                  {...register("name", { required: true })}
                  helperText={errors.name ? "Field required" : ""}
                  // onChange={(e) => setName(e.target.value)}
                  label="Name"
                />

                <TextField
                  id="outlined-required"
                  label="Start Date"
                  required
                  /*  min={moment(new Date(project.start_date?.iso)).format(
                    "YYYY-MM-DD hh:mm"
                  )} */
                  /*   defaultValue={moment(
                    new Date(project.start_date?.iso)
                  ).format("YYYY-MM-DD hh:mm")} */
                  min={project.start_date?.iso.slice(0, -8)}
                  defaultValue={project.start_date?.iso.slice(0, -8)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="datetime-local"
                  {...register("start_date", { required: true })}
                  helperText={errors.start_date ? "Field required" : ""}
                  error={errors.start_date}
                  //   onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="outlined-number"
                  label="Amount"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={project.amount}
                  //  value={project.amount}
                  type="number"
                  error={errors.amount}
                  {...register("amount", { required: true })}
                  helperText={errors.amount ? "Field required" : ""}
                  //  onChange={(e) => setAmount(e.target.value)}
                />
                <TextField
                  type="number"
                  required
                  value={project.return_rate}
                  error={errors.return_rate}
                  {...register("return_rate", { required: true })}
                  helperText={errors.return_rate ? "Field required" : ""}
                  //  onChange={(e) => setRate(e.target.value)}
                  label="Rate"
                />
              </div>

              <div>
                <TextField
                  id="outlined-number"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={project.month_investment_maturity}
                  label="Month investment maturity "
                  {...register("month_investment_maturity", { required: true })}
                  error={errors.month_investment_maturity}
                  helperText={
                    errors.month_investment_maturity ? "Field required" : ""
                  }
                  //onChange={(e) => setMonthInvest(e.target.value)}
                  type="number"
                />
                <TextField
                  type="file"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // value={project.month_investment_maturity}
                  label="Project description"
                  {...register("complete_description", { required: true })}
                  helperText={
                    errors.complete_description ? "Field required" : ""
                  }
                  error={errors.complete_description}
                  // onChange={(e) => setDocumentDescription(e.target.files[0])}
                />
              </div>
              <div>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  required
                  {...register("description", { required: true })}
                  error={errors.description}
                  //onChange={(e) => setDescription(e.target.value)}
                  label="Description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={project.description}
                  helperText={errors.description ? "Field required" : ""}
                  rows={4}
                />

                <TextField
                  select
                  required
                  label="Investment Type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={project.investmentType.task_name}
                  error={errors.investmentType}
                  {...register("investmentType", { required: true })}
                  //onChange={(e) => setType(e.target.value)}
                  helperText={errors.investmentType ? "Field required" : ""}
                >
                  {investmentTypes.map((option, k) => (
                    <MenuItem key={k} value={option.task_name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <a href={project.complete_description.url}>
                  {" "}
                  <CloudDownloadIcon />
                </a>
              </div>

              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                {/* <Link to={"/admin/projects"} underline="none">
                  <Button variant="outlined">Cancel</Button>
                </Link> */}
                {/*   <Button
                type="reset"
                sx={{
                  marginLeft: "15px",
                }}
               
                variant="outlined"
              >
                Close
              </Button> */}
                <Button
                  type="reset"
                  sx={{
                    marginLeft: "15px",
                  }}
                  onClick={() => onSHowProjectForm(!projectForm)}
                  variant="outlined"
                >
                  Close
                </Button>

                <Button
                  type="submit"
                  sx={{
                    marginLeft: "15px",
                  }}
                  variant="contained"
                >
                  Save changes
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
      )}
    </div>
  );
}
