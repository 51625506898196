import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {provider} from './provider'
import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
} from 'react-admin';
import app  from './app'


/**GESTION DE L'ETAT DE l'APP AVEC SAGA REDUX */

const reducer = combineReducers({
  admin: adminReducer,
  router: connectRouter(provider.history),
  app: app 
});

const saga = function* rootSaga() {
    yield all(
        [
            adminSaga(provider.dataProvider)
        ].map(fork)
    );
};


const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =(process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) || compose;

const resettableAppReducer = (state, action) =>  reducer(action.type !== USER_LOGOUT ? state : undefined, action);

const store = createStore(
    resettableAppReducer,
    {},
    composeEnhancers(
        applyMiddleware(
            sagaMiddleware,
            routerMiddleware(provider.history),
        )
    ),        
);

sagaMiddleware.run(saga);



export default store