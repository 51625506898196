import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { config } from './config';
import reportWebVitals from './reportWebVitals';

    (async () =>{
        const script = document.createElement('script')  
        script.src = config['auth-server-url']+"/js/keycloak.js"
        script.async = false
        script.onload = () => {
            ReactDOM.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
              document.getElementById('root')
            );
        }
        document.head.appendChild(script)
      }
    )()



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
